import 'react-perfect-scrollbar/dist/css/styles.css';
import './__mocks__';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StyledEngineProvider } from '@mui/material/styles';
import Amplify, { Auth } from 'aws-amplify';
import App from './App';
import { AuthProvider } from './contexts/AmplifyContext';
import { SettingsProvider } from './contexts/SettingsContext';
import * as serviceWorker from './serviceWorker';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_PROJECT_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'surveyor-API',
        endpoint: process.env.REACT_APP_API_URL,
        custom_header: async () => (
          { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        )
      },
      {
        name: 'surveyor-API-UnAuth',
        endpoint: process.env.REACT_APP_API_URL
      }
    ]
  }
});

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <BrowserRouter>
              <AuthProvider>
                <App />
              </AuthProvider>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
//
// remember the import if we enable this:
// import reportWebVitals from './reportWebVitals';
