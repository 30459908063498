import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import PropTypes from 'prop-types';

function OverviewAnalysisScript(props) {
  const { script } = props;

  return (
    <SyntaxHighlighter
      {...props}
      language="javascript"
      style={vscDarkPlus}
      wrapLongLines
      showInlineLineNumbers
    >
      {script}
    </SyntaxHighlighter>
  );
}

OverviewAnalysisScript.propTypes = {
  script: PropTypes.string
  // clientID: PropTypes.object
};

export default OverviewAnalysisScript;
