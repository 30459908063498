import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/system';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 64
}));

function MainLayout({ children }) {
  return (
    <MainLayoutRoot>
      {children || <Outlet />}
    </MainLayoutRoot>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
