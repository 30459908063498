import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import Label from '../../Label';
import Scrollbar from '../../Scrollbar';
import ArrowRightIcon from '../../../icons/ArrowRight';

const applyPagination = (versions, page, limit) => versions
  .slice(page * limit, page * limit + limit);

function ScriptVersions(props) {
  const { versions, ...other } = props;
  const [p, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  console.log(props);
  // const getVersions = useCallback(async () => {
  //   try {
  //     const response = await API.get(apiName, path);

  //     if (isMountedRef.current) {
  //       setVersions(response);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [isMountedRef]);

  // useEffect(() => {
  //   getVersions();
  // }, [getVersions]);
  const handleLinkPress = () => {
    useEffect(() => {
    }, []);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };
  const paginatedVersions = applyPagination(versions, p, limit);
  if (!versions) {
    return null;
  }
  return (
    <Card {...other}>
      <CardHeader title="Versions" />
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Created
                </TableCell>
                <TableCell>
                  Script
                </TableCell>
                <TableCell>
                  ID
                </TableCell>
                <TableCell>
                  Link
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedVersions.map((version) => (
                <TableRow key={version.ID}>
                  <TableCell width={100}>
                    <Typography
                      color="textPrimary"
                      variant="outlined"
                    >
                      {version.active
                        ? (
                          <Label color="success">
                            Active
                          </Label>
                        ) :
                        (
                          <Label color="warning">
                            Inactive
                          </Label>
                        )}
                    </Typography>
                  </TableCell>
                  <TableCell width={100}>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="center"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${version.created_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                      </Typography>
                      <Typography
                        align="center"
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${version.created_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="left"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {version.script_data.search('http') !== 0
                          ? (
                            <SyntaxHighlighter
                              {...props}
                              language="javascript"
                              style={vscDarkPlus}
                              wrapLongLines
                              showInlineLineNumbers
                            >
                              {version.script_data}
                            </SyntaxHighlighter>
                          )
                          :
                          version.script_data}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {version.script_match_id}
                  </TableCell>
                  <TableCell
                    align="center"
                    width={50}
                  >
                    <IconButton
                      component={RouterLink}
                      to={`/dashboard/detail/script/${version.script_match_id}`}
                      onClick={handleLinkPress}
                    >
                      <ArrowRightIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={versions.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={p}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

ScriptVersions.propTypes = {
  versions: PropTypes.array.isRequired
};

export default ScriptVersions;
