import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Dialog,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Label from '../../Label';
import TemplateCode from './TemplateCode';
import X from '../../../icons/X';

function PageScanTemplates(props) {
  const { pageScanTemplates, ...other } = props;

  const [modalOpen, setDialogOpen] = useState(false);
  const [editTemplateId, setEditTemplateId] = useState(null);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const editTemplate = (templateId) => {
    setEditTemplateId(templateId);
    setDialogOpen(true);
  };

  return (
    <>
      <Card {...other}>
        <CardHeader title="Page Scan Templates" />
        <Divider />
        {pageScanTemplates.length === 0 && (<Typography sx={{ margin: 2 }}>No Templates Found</Typography>)}
        {pageScanTemplates.map((template) => {
          return (
            <Accordion key={template.ID}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                <Grid container sx={{ width: '97%', justifyContent: 'space-between' }}>
                  <Grid item sm={4} display="flex" alignItems="left">
                    <Typography variant="button">Name:&nbsp;</Typography>
                    <Typography>{template.name}</Typography>
                  </Grid>
                  <Grid item display="flex" alignItems="left">
                    <Typography variant="button">Created:&nbsp;</Typography>
                    <Typography>{new Date(template.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</Typography>
                  </Grid>
                  <Grid item display="flex" alignItems="left">
                    <Typography variant="button">Modified:&nbsp;</Typography>
                    <Typography>{new Date(template.modified_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</Typography>
                  </Grid>
                  <Grid item display="flex" alignItems="left">
                    <IconButton onClick={() => editTemplate(template.latest_scan.template_id)} sx={{ p: 0 }}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0 }}>
                {template.latest_scan_results.map((result) => {
                  return (
                    <Grid container sx={{ width: '100%', justifyContent: 'space-between' }} key={result.ID}>
                      <Grid item sm={12}><Typography variant="body1" sx={{ fontWeight: 'bold' }}>Scan Results:</Typography></Grid>
                      <Grid item sm={12}><Divider sx={{ mb: 1 }} /></Grid>
                      <Grid item sm={2} sx={{ display: 'flex', justifyContent: 'flex-end', pr: 2, pb: 0.5 }}><Typography variant="body2" sx={{ fontWeight: 'bold' }}>Extracted Results:</Typography></Grid>
                      <Grid item sm={10}>
                        <Typography variant="body2">
                          {result['extracted-results']}
                        </Typography>
                      </Grid>
                      <Grid item sm={2} sx={{ display: 'flex', justifyContent: 'flex-end', pr: 2, pb: 0.5 }}><Typography variant="body2" sx={{ fontWeight: 'bold' }}>Matcher Name:</Typography></Grid>
                      <Grid item sm={10}>
                        <Typography variant="body2">
                          {result['matcher-name']}
                        </Typography>
                      </Grid>
                      <Grid item sm={2} sx={{ display: 'flex', justifyContent: 'flex-end', pr: 2, pb: 0.5 }}><Typography variant="body2" sx={{ fontWeight: 'bold' }}>Matcher Status:</Typography></Grid>
                      <Grid item sm={10}>
                        <Typography variant="body2">
                          {String(result['matcher-status'])}
                        </Typography>
                      </Grid>
                      <Grid item sm={2} sx={{ display: 'flex', justifyContent: 'flex-end', pr: 2, pb: 0.5 }}><Typography variant="body2" sx={{ fontWeight: 'bold' }}>Severity:</Typography></Grid>
                      <Grid item sm={10}>
                        <Typography variant="body2">
                          {result.severity}
                        </Typography>
                      </Grid>
                      <Grid item sm={2} sx={{ display: 'flex', justifyContent: 'flex-end', pr: 2, pb: 0.5 }}><Typography variant="body2" sx={{ fontWeight: 'bold' }}>Tags:</Typography></Grid>
                      <Grid item sm={10}>
                        <Typography variant="body2">
                          {result.tags.map((tag) => {
                            return (
                              <Label key={tag} variant="outlined" sx={{ mr: 1 }}>{tag}</Label>
                            );
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Card>
      <Dialog fullScreen open={modalOpen} onClose={() => handleClose}>
        <Box p={1}>
          <Grid container sx={{ width: '100%', pt: 1, pb: 1 }}>
            <Grid item sm={11}>
              <Typography>Editing Template:</Typography>
            </Grid>
            <Grid item sm={1} display="flex" justifyContent="flex-end">
              <IconButton onClick={handleClose} sx={{ p: 0 }}>
                <X />
              </IconButton>
            </Grid>
          </Grid>
          <TemplateCode templateid={editTemplateId} />
        </Box>
      </Dialog>
    </>
  );
}

PageScanTemplates.propTypes = {
  pageScanTemplates: PropTypes.array.isRequired
};

export default PageScanTemplates;
