import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

function DashboardNavbar(props) {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64, paddingLeft: '15px !important' }}>
        <Box sx={{ display: 'flex', justifyContent: { xs: 'left', lg: 'left' }, flexGrow: '7' }}>
          <img src="/static/logo.png" alt="redpoint logo" width="40" />
          <Typography sx={{
            ml: 1,
            color: '#FFF',
            fontSize: '1.5rem',
            fontFamily: 'Raleway, arial, sans-serif',
            letterSpacing: '0.25em' }}
          >
            SURVEYOR
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexGrow: '1', ml: 2, justifyContent: 'flex-end' }}>
          <AccountPopover />
        </Box>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarMobileOpen} sx={{ ml: 2, pr: 0 }}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
      </Toolbar>
    </DashboardNavbarRoot>
  );
}

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
