import Chart from 'react-apexcharts';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Tooltip
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import InformationCircleIcon from '../../../icons/InformationCircle';

function HostXReqSummary(props) {
  const { host } = props;
  const theme = useTheme();

  const chart = {
    options: {
      chart: {
        background: 'transparent',
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: false
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false
        }
      },
      dataLabels: {
        enabled: false
      },
      labels: Object.keys(host.sources.xreq_summary),
      legend: {
        labels: {
          colors: theme.palette.text.secondary
        },
        show: true
      },
      stroke: {
        width: 0
      },
      theme: {
        mode: theme.palette.mode
      }
    },
    series: [{
      name: 'Connections',
      data: Object.values(host.sources.xreq_summary)
    }]
  };

  return (
    <Card {...props}>
      <CardHeader
        disableTypography
        title={(
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Network Request Summary
            </Typography>
            <Tooltip title="Number of scripts per domain">
              <InformationCircleIcon fontSize="small" />
            </Tooltip>
          </Box>
        )}
      />
      <CardContent>
        <Chart
          height={300}
          type="bar"
          {...chart}
        />
      </CardContent>
    </Card>
  );
}

HostXReqSummary.propTypes = {
  host: PropTypes.array
};

export default HostXReqSummary;
