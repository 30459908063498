import Chart from 'react-apexcharts';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Tooltip,
  CardActions
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import ArrowRightIcon from '../../../icons/ArrowRight';
import InformationCircleIcon from '../../../icons/InformationCircle';

function OverviewFindings(props) {
  const { risksdata } = props;
  const theme = useTheme();

  const chart = {
    options: {
      chart: {
        background: 'transparent',
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: false
      },
      colors: [
        '#767f9c',
        '#4CAF50',
        '#FF9800',
        '#F44336',
        '#F06191'
      ],
      dataLabels: {
        enabled: false
      },
      labels: ['Informational', 'Low', 'Medium', 'High', 'Critical'],
      legend: {
        labels: {
          colors: theme.palette.text.secondary
        },
        show: true
      },
      stroke: {
        width: 0
      },
      theme: {
        mode: theme.palette.mode
      }
    },
    series: risksdata
  };

  return (
    <Card {...props}>
      <CardHeader
        disableTypography
        title={(
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Findings
            </Typography>
            <Tooltip title="Number of findings across all scripts">
              <InformationCircleIcon fontSize="small" />
            </Tooltip>
          </Box>
        )}
      />
      <CardContent>
        <Chart
          height={200}
          type="donut"
          {...chart}
        />
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          endIcon={<ArrowRightIcon fontSize="small" />}
          variant="text"
          component={RouterLink}
          to="/dashboard/detail/findings"
        >
          All Findings
        </Button>
      </CardActions>
    </Card>
  );
}

OverviewFindings.propTypes = {
  risksdata: PropTypes.array
};

export default OverviewFindings;
