import PropTypes from 'prop-types';
// import { Link as useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { format } from 'date-fns';
import ReactLoading from 'react-loading';
// import Label from '../../Label';
function ScanSummary(props) {
  const { template, ...other } = props;
  const color = '#688eff';
  const type = 'spinningBubbles';
  // const { scanId } = useParams();

  console.log(template);
  if (!template) {
    return (
      <ReactLoading
        type={type}
        color={color}
        height={1055}
        width={100}
      />
    );
  }

  if (!template) {
    return null;
  }

  return (
    <Card {...other}>
      <CardHeader
        disableTypography
        title={(
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Scan Template Info
            </Typography>
          </Box>
        )}
      />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {template.name}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Description
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {template.description}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Host
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {template.hostname}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                URL
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {template.url}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Created At
              </Typography>
            </TableCell>
            <TableCell>
              <Box sx={{ p: 1 }}>
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {format(new Date(`${template.created_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                </Typography>
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {format(new Date(`${template.created_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Author
              </Typography>
            </TableCell>
            <TableCell>
              <Box sx={{ p: 1 }}>
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {template.author}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Tags
              </Typography>
            </TableCell>
            <TableCell>
              <Box sx={{ p: 1 }}>
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {template.tags.split(',').join(', ')}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Words
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                Words
              </Typography>
            </TableCell>
          </TableRow> */}
          {/* <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Number of times this script was seen
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {numeral(url.event_count)
                  .format(`${url.event_count}0,0`)}
              </Typography>
            </TableCell>
          </TableRow> */}
          {/* <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Words
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                Words
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Words
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                Words
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Cookies present
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                Words
              </Typography>
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </Card>
  );
}

ScanSummary.propTypes = {
  // @ts-ignore
  template: PropTypes.object.isRequired
};

export default ScanSummary;
