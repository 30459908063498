import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Divider, Link, Typography } from '@mui/material';
import { LoginAmplify } from '../../components/authentication/login';
import useAuth from '../../hooks/useAuth';

function Login() {
  const { platform } = useAuth();

  return (
    <>
      <Helmet>
        <title>Login | Redpoint Surveyor</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Container maxWidth="sm" sx={{ py: '80px' }}>
          <Card>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', p: 4 }}>
              <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Login
                  </Typography>
                </div>
                <Box sx={{ height: 80, '& > img': { maxHeight: '100%', width: 'auto' } }}>
                  <img alt="Logo" src="/static/logo.png" />
                </Box>
              </Box>
              <Box sx={{ flexGrow: 1, mt: 3 }}>
                {platform === 'Amplify' && <LoginAmplify />}
              </Box>
              <Divider sx={{ my: 3 }} />
              <Link color="textSecondary" component={RouterLink} to="/authentication/register" variant="body2">
                Create new account
              </Link>
              {platform === 'Amplify' && (
                <Link color="textSecondary" component={RouterLink} sx={{ mt: 1 }} to="/authentication/password-recovery" variant="body2">
                  Forgot password
                </Link>
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
}

export default Login;
