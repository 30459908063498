import PropTypes from 'prop-types';
import {
  // Card,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';

function HostScripts(props) {
  const { host } = props;

  if (!host.sources.script) {
    return null;
  }
  return (
          <Table>
            <TableBody>
              {Object.keys(host.sources.script).sort((a, b) => a.localeCompare(b)).map((script) => (
                <TableRow
                  hover
                  key={script}
                >
                  <TableCell align="left">
                    {script}
                  </TableCell>
                  <TableCell align="right">
                    {host.sources.script[script]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
  );
}

HostScripts.propTypes = {
  host: PropTypes.object.isRequired
};

export default HostScripts;
