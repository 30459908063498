import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material'; // Button
import { API } from 'aws-amplify';
import useAuth from '../../hooks/useAuth';
import { FindingsListTable } from '../../components/dashboard/detail';
import ChevronRightIcon from '../../icons/ChevronRight';
import useSettings from '../../hooks/useSettings';

const apiName = 'surveyor-API';

function FindingsDetails() {
  const { user } = useAuth();
  const findingsPath = (user.role === 'rdpt_admin' ? '/admin/findings' : '/findings');
  // const findingsPath = '/findings';
  const { settings } = useSettings();
  const [findings, setFindings] = useState([]);
  const type = 'spinningBubbles';
  const color = '#688eff';

  useEffect(async () => {
    try {
      const response = await API.get(apiName, findingsPath);
      response.forEach((finding, index) => {
        response[index].hasCVEs = (finding.cve !== null && finding.cve !== 'N/A');
        response[index].hasData = (finding.data !== null || finding.data !== 'N/A');
        response[index].dataSearchString = (finding.data === null || finding.data.toUpperCase() === 'N/A' ? '' : finding.data);
        response[index].cveSearchString = (finding.cve === null || finding.cve.toUpperCase() === 'N/A' ? '' : finding.cve);
        response[index].data = (finding.data === null || finding.data.toUpperCase() === 'N/A' ? ['N/A'] : finding.data.split(','));
        response[index].cve = (finding.cve === null || finding.cve.toUpperCase() === 'N/A' ? ['N/A'] : finding.cve.split(','));
        response[index].component = (finding.component === null ? 'N/A' : finding.component);
        response[index].isInfo = (finding.severity.toLowerCase() === 'info');
        response[index].isLow = (finding.severity.toLowerCase() === 'low');
        response[index].isMedium = (finding.severity.toLowerCase() === 'medium');
        response[index].isHigh = (finding.severity.toLowerCase() === 'high');
        response[index].isCritical = (finding.severity.toLowerCase() === 'critical');
        response[index].isScript = (finding.type === 'script');
        response[index].summary = (finding.summary === null ? '' : finding.summary);
        response[index].severityScore = (finding.severity.toLowerCase() === 'info' ? 0 : finding.severity.toLowerCase() === 'low' ? 1 : finding.severity.toLowerCase() === 'medium' ? 2 : finding.severity.toLowerCase() === 'high' ? 3 : 4);
      });
      await setFindings(response);
      console.log('@@@@@@@@@@@@ useEffect call');
      console.log(response);
    } catch (err) {
      console.error(err);
    }
  }, []);
  console.log('____________________________-_-_-_-_-_____----____-_____-------->');

  return (
    <>
      <Helmet>
        <title>Dashboard: Findings List | Surveyor</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Findings List
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Detail
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Findings
                </Typography>
              </Breadcrumbs>
              <Box
                sx={{
                  mb: -1,
                  mx: -1,
                  mt: 1
                }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              mt: 3,
              textAlign: 'center'
            }}
          >
            { findings !== undefined
              ? (
                <FindingsListTable findings={findings} />
              )
              : (
                <ReactLoading
                  type={type}
                  color={color}
                  height={1055}
                  width={100}
                />
              ) }
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default FindingsDetails;
