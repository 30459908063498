import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import Label from '../../Label';
import SearchIcon from '../../../icons/Search';
import Scrollbar from '../../Scrollbar';
import ArrowRightIcon from '../../../icons/ArrowRight';

const tabs = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Script',
    value: 'isScript'
  },
  {
    label: 'Informational',
    value: 'isInfo'
  },
  {
    label: 'Low',
    value: 'isLow'
  },
  {
    label: 'Medium',
    value: 'isMedium'
  },
  {
    label: 'High',
    value: 'isHigh'
  },
  {
    label: 'Critical',
    value: 'isCritical'
  },
  {
    label: 'Has CVEs',
    value: 'hasCVEs'
  }
];

const sortOptions = [
  {
    label: 'Severity (highest)',
    value: 'severityScore|desc'
  },
  {
    label: 'Severity (lowest)',
    value: 'severityScore|asc'
  }
];

const applyFilters = (findings, query, filters) => findings
  .filter((finding) => {
    let matches = true;
    if (query) {
      const properties = ['component', 'summary', 'cveSearchString', 'hostname', 'dataSearchString'];
      let containsQuery = false;
      properties.forEach((property) => {
        if (finding[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && finding[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (findings, page, limit) => findings
  .slice(page * limit, page * limit + limit);

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const applySort = (findings, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = findings.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

function FindingsListTable(props) {
  const { findings, ...other } = props;
  const [currentTab, setCurrentTab] = useState('all');
  const [selectedFindings, setSelectedFindings] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  const [filters, setFilters] = useState({
    isInfo: null,
    isLow: null,
    isMedium: null,
    isHigh: null,
    isCritical: null,
    hasCVEs: null,
    isScript: null
  });

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      isInfo: null,
      isLow: null,
      isMedium: null,
      isHigh: null,
      isCritical: null,
      hasCVEs: null,
      isScript: null
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }

    setFilters(updatedFilters);
    setSelectedFindings([]);
    setCurrentTab(value);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleSelectAllFindings = (event) => {
    setSelectedFindings(event.target.checked
      ? findings.map((finding) => finding.ID)
      : []);
  };

  // const handleSelectOneFinding = (event, findingId) => {
  //   if (!selectedFindings.includes(findingId)) {
  //     setSelectedFindings((prevSelected) => [...prevSelected, findingId]);
  //   } else {
  //     setSelectedFindings((prevSelected) => prevSelected.filter((id) => id !== findingId));
  //   }
  // };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const filteredFindings = applyFilters(findings, query, filters);
  const sortedFindings = applySort(filteredFindings, sort);
  const paginatedFindings = applyPagination(sortedFindings, page, limit);
  const enableBulkActions = selectedFindings.length > 0;
  const selectedSomeFindings = selectedFindings.length > 0
    && selectedFindings.length < findings.length;
  const selectedAllFindings = selectedFindings.length === findings.length;

  return (
    <Card {...other}>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Search findings"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            width: 240
          }}
        >
          <TextField
            label="Sort By"
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
            value={sort}
            variant="outlined"
          >
            {sortOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>
      </Box>
      {enableBulkActions && (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              mt: '6px',
              position: 'absolute',
              px: '4px',
              width: '100%',
              zIndex: 2
            }}
          >
            <Checkbox
              checked={selectedAllFindings}
              color="primary"
              indeterminate={selectedSomeFindings}
              onChange={handleSelectAllFindings}
            />
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Delete
            </Button>
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Edit
            </Button>
          </Box>
        </Box>
      )}
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllFindings}
                    color="primary"
                    indeterminate={selectedSomeFindings}
                    onChange={handleSelectAllFindings}
                  />
                </TableCell> */}
                <TableCell>
                  Severity
                </TableCell>
                <TableCell>
                  Identification
                </TableCell>
                <TableCell>
                  Summary
                </TableCell>
                <TableCell>
                  Host
                </TableCell>
                <TableCell>
                  Recommendations
                </TableCell>
                <TableCell>
                  CVE(s)
                </TableCell>
                <TableCell>
                  View Item
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedFindings.map((finding) => {
                const isFindingSelected = selectedFindings.includes(finding.ID);

                return (
                  <TableRow
                    hover
                    key={finding.ID}
                    selected={isFindingSelected}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={isFindingSelected}
                        color="primary"
                        onChange={(event) => handleSelectOneFinding(event, finding.ID)}
                        value={isFindingSelected}
                      />
                    </TableCell> */}
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {finding.severity.toUpperCase() === 'CRITICAL' ? (
                          <Label color="error">
                            CRITICAL
                          </Label>
                        ) : finding.severity.toUpperCase() === 'HIGH' ? (
                          <Label color="secondary">
                            HIGH
                          </Label>
                        ) : finding.severity.toUpperCase() === 'MEDIUM' ? (
                          <Label color="warning">
                            MEDIUM
                          </Label>
                        ) : finding.severity.toUpperCase() === 'LOW' ? (
                          <Label color="primary">
                            LOW
                          </Label>
                        ) : finding.severity.toUpperCase() === 'INFO' ? (
                          <Label color="success">
                            INFORMATIONAL
                          </Label>
                        ) : '' }
                      </Typography>
                    </TableCell>
                    <TableCell
                      width={100}
                    >
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="center"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {finding.component}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="left"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {finding.summary}
                        </Typography>
                        <Typography
                          align="left"
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {finding.hasData ? finding.data.map((d) => (
                            <div id={d}>
                              {d.search('http') === 0
                                ? (
                                  <Link
                                    href={`${d}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {d}
                                  </Link>
                                ) :
                                `${d}`}
                            </div>
                          )) :
                            'None'}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="left"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {finding.hostname}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="left"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {finding.title_rec}
                        </Typography>
                        <Typography
                          align="left"
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {finding.text_rec}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        align="left"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {finding.hasCVEs ? finding.cve.map((c) => (
                          <div id={c}>
                            <Link
                              href={`https://nvd.nist.gov/vuln/detail/${c}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {c}
                            </Link>
                          </div>
                        )) :
                          'None'}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      width={100}
                    >
                      {finding.type === 'script' ?
                        (
                          <IconButton
                            component={RouterLink}
                            to={finding.type === 'xrequest' ? `/dashboard/detail/page/${finding.url_id}` : `/dashboard/detail/script/${finding.item_id}`}
                          >
                            <ArrowRightIcon fontSize="small" />
                          </IconButton>
                        )
                        :
                        ''}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={filteredFindings.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

FindingsListTable.propTypes = {
  findings: PropTypes.array.isRequired
};

export default FindingsListTable;
