import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
// import numeral from 'numeral';
import { format } from 'date-fns';
import { API } from 'aws-amplify';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import Scrollbar from '../../Scrollbar';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const apiName = 'surveyor-API';

const applyPagination = (scripts, page, limit) => scripts
  .slice(page * limit, page * limit + limit);

function ScriptPages(props) {
  const { user } = useAuth();
  const { scriptItems, ...other } = props;
  const [pages, setPages] = useState([]);
  const isMountedRef = useIsMountedRef();
  const [p, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const path = (user.role === 'rdpt_admin' ? `/admin/urls/script/${scriptItems.ID}` : `/urls/script/${scriptItems.ID}`);
  const getPages = useCallback(async () => {
    try {
      const response = await API.get(apiName, path);

      if (isMountedRef.current) {
        setPages(response);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getPages();
  }, [getPages]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };
  const paginatedPages = applyPagination(pages, p, limit);
  if (!pages) {
    return null;
  }
  return (
    <Card {...other}>
      <CardHeader title="Pages" />
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>
                  Count
                </TableCell> */}
                <TableCell>
                  Last Seen
                </TableCell>
                <TableCell>
                  First Seen
                </TableCell>
                <TableCell>
                  URL
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedPages.map((page) => (
                <TableRow key={page.ID}>
                  {/* <TableCell width={50}>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {numeral(page.COUNT)
                        .format(`${page.COUNT}0,0`)}
                    </Typography>
                  </TableCell> */}
                  <TableCell width={100}>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="center"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${page.modified_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                      </Typography>
                      <Typography
                        align="center"
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${page.modified_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell width={100}>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="center"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${page.created_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                      </Typography>
                      <Typography
                        align="center"
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${page.created_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {page.full_url}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={pages.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={p}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

ScriptPages.propTypes = {
  scriptItems: PropTypes.array.isRequired
};

export default ScriptPages;
