import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { API } from 'aws-amplify';
import {
  Box,
  IconButton,
  Button,
  Checkbox,
  Divider,
  TextField,
  Grid,
  FormControlLabel,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
// import useAuth from '../../../hooks/useAuth';
// import useIsMountedRef from '../../../hooks/useIsMountedRef';

const apiName = 'surveyor-API';
const severityOptions = [
  { label: 'Critical', value: 'critical' },
  { label: 'High', value: 'high' },
  { label: 'Medium', value: 'medium' },
  { label: 'Low', value: 'low' },
  { label: 'Informational', value: 'info' }
];

const HostNotificationsModal = ({ handleClose, modalOpen, hostId, hostname, getNotificationPrefs }) => {
  if (!modalOpen) {
    return null;
  }

  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [prefs, setPrefs] = useState({
    hostId: '',
    hostname: 'Loading...',
    slack_status: false,
    slack_webhook: 'https://hooks.slack.com/...',
    email_status: false,
    email: '',
    severity_level: 'info',
    weekly_report_status: false
  });

  useEffect(() => {
    getNotificationPrefs(hostId, hostname).then((data) => setPrefs(data));
  }, []);

  const dialogClose = () => {
    handleClose();
    setFormSubmitted(false);
  };

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPrefs({
      ...prefs,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const params = {
      body: {
        ID: prefs.ID,
        email_status: prefs.email_status === true ? 1 : 0,
        slack_status: prefs.slack_status === true ? 1 : 0,
        weekly_report_status: prefs.weekly_report_status === true ? 1 : 0,
        slack_webhook: (prefs.slack_webhook === null ? '' : prefs.slack_webhook),
        email: prefs.email,
        severity_level: (prefs.severity_level === null || prefs.severity_level === 'select' ? '' : prefs.severity_level)
      }
    };
    console.log('params*******************');
    console.log(params);
    const response = await API.post(apiName, '/notification-prefs', params);
    console.log(response);
    if (response) {
      enqueueSnackbar(`${prefs.hostname} notifications preferences updated!`, {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        autoHideDuration: 5000,
        variant: 'success'
      });
    }
    dialogClose();
  };

  return (
    <Dialog open={modalOpen} onClose={dialogClose}>
      <DialogTitle>
        Notification Configuration
        <IconButton
          aria-label="close"
          onClick={dialogClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} id="enterprise_plan_form">
          <Grid key={prefs.ID} item md={4} sm={6} xs={12}>
            <Typography color="textPrimary" variant="h5">
              {prefs.hostname}
            </Typography>
            <Divider />
            <Typography variant="subtitle1" sx={{ mb: 2, mt: 2 }}>
              Mute notifications below severity level:
            </Typography>
            <TextField
              fullWidth
              label="Severity Level"
              name="severity_level"
              select
              SelectProps={{ native: true }}
              variant="outlined"
              value={prefs.severity_level ? prefs.severity_level.toLowerCase() : 'select'}
              onChange={handleFormChange}
            >
              {severityOptions.map((category) => (
                <option key={category.value} value={category.value}>
                  {category.label}
                </option>
              ))}
            </TextField>
            <FormControlLabel
              control={(
                <Checkbox
                  color="primary"
                  checked={prefs.email_status}
                  onChange={handleFormChange}
                  name="email_status"
                />
              )}
              label="Email Event Alerts"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  color="primary"
                  checked={prefs.weekly_report_status}
                  onChange={handleFormChange}
                  name="weekly_report_status"
                />
              )}
              label="Weekly Email Report"
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={prefs.email}
              variant="outlined"
              onChange={handleFormChange}
            />
            <FormControlLabel
              control={(<Checkbox />)}
              name="slack_status"
              color="primary"
              checked={prefs.slack_status}
              onChange={handleFormChange}
              label="Slack Notifications"
            />
            <TextField
              fullWidth
              label="Slack Webhook"
              name="slack_webhook"
              value={prefs.slack_webhook ? prefs.slack_webhook : ''}
              variant="outlined"
              onChange={handleFormChange}
            />
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button color="secondary" sx={{ ml: 2 }} type="submit" variant="contained" onClick={dialogClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button color="primary" sx={{ ml: 2 }} type="submit" variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
              Submit
            </Button>
          </Box>
        </form>
        <Box sx={{ mt: 2, display: formSubmitted ? 'block' : 'none' }}>
          <Box sx={{ mt: 2, width: '330px', textAlign: 'center' }}>
            <Box><strong>Thank You!</strong></Box>
            <Box sx={{ mt: 2 }}>We will get back to you soon</Box>
          </Box>
          <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button color="secondary" sx={{ ml: 2 }} type="submit" variant="contained" onClick={dialogClose} disabled={isSubmitting}>
              Close
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

HostNotificationsModal.propTypes = {
  handleClose: propTypes.func,
  modalOpen: propTypes.bool,
  hostId: propTypes.string,
  getNotificationPrefs: propTypes.func,
  hostname: propTypes.string
};

export default HostNotificationsModal;
