import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
// import DocsLayout from './components/docs/DocsLayout'; // for future documentation pages
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import FindingsDetails from './pages/dashboard/FindingsDetails';
import HostDetails from './pages/dashboard/HostDetails';

const Loadable = (Component) => function loadable(props) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));
const NewUser = Loadable(lazy(() => import('./pages/authentication/NewUser')));
// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const ScriptList = Loadable(lazy(() => import('./pages/dashboard/ScriptList')));
const NotificationDetails = Loadable(lazy(() => import('./pages/dashboard/NotificationDetails')));
const PagesDetails = Loadable(lazy(() => import('./pages/dashboard/PagesDetails')));
const ScanList = Loadable(lazy(() => import('./pages/dashboard/ScanList')));
// const FindingsDetails = Loadable(lazy(() => import('./pages/dashboard/FindingsDetails')));
const HostList = Loadable(lazy(() => import('./pages/dashboard/HostList')));
const ScriptDetails = Loadable(lazy(() => import('./pages/dashboard/ScriptDetails')));
const ScanDetails = Loadable(lazy(() => import('./pages/dashboard/ScanDetails')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const PageDetails = Loadable(lazy(() => import('./pages/dashboard/PageDetails')));

// management pages

const Users = Loadable(lazy(() => import('./pages/management/Users')));
const Clients = Loadable(lazy(() => import('./pages/management/Clients')));

// Docs pages (future use)

// const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));
const Inactive = Loadable(lazy(() => import('./pages/Inactive')));

// Other pages
const Contact = Loadable(lazy(() => import('./pages/Contact')));

// Unused now - but possible future page
// const Contact = Loadable(lazy(() => import('./pages/Contact')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'new-user',
        element: (
          <GuestGuard>
            <NewUser />
          </GuestGuard>
        )
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'contact',
    element: <Contact />
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'detail',
        children: [
          {
            path: 'scripts',
            element: <ScriptList />
          },
          {
            path: 'notifications',
            element: <NotificationDetails />
          },
          {
            path: 'pages',
            element: <PagesDetails />
          },
          {
            path: 'hosts',
            element: <HostList />
          },
          {
            path: 'script/:scriptId',
            element: <ScriptDetails />
          },
          {
            path: 'scan/:scanId',
            element: <ScanDetails />
          },
          {
            path: 'page/:urlId',
            element: <PageDetails />
          },
          {
            path: 'host/:hostId',
            element: <HostDetails />
          },
          {
            path: 'findings',
            element: <FindingsDetails />
          },
          {
            path: 'scan',
            element: <ScanList />
          }
        ]
      },
      {
        path: 'users',
        element: (
          <Users />
        )
      },
      {
        path: 'clients',
        element: (
          <Clients />
        )
      }
    ]
  },
  /* For future use as documentation pages
  { path: 'docs',
    element: <DocsLayout />,
    children: [
      {
        path: 'docs/',
        element: (
          <Navigate
            to="/docs/overview/welcome"
            replace
          />
        )
      },
      {
        path: '*',
        element: <Docs />
      }
    ]
  }, */
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '*',
        element: (
          <Navigate
            to="/dashboard"
            replace
          />
        )
      },
      {
        path: 'inactive',
        element: <Inactive />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
