import { useState } from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import {
  Box,
  IconButton,
  Button,
  TextField,
  Modal,
  Paper,
  Tooltip,
  Grid,
  Typography
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import PencilAlt from '../../../icons/PencilAlt';
import XIcon from '../../../icons/X';

const statusOptions = [
  {
    label: 'Inactive',
    value: 'inactive'
  },
  {
    label: 'Active',
    value: 'active'
  }
];

const rolesOptions = [
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'User',
    value: 'user'
  }
];

const rdptAdminRolesOptions = [
  {
    label: 'RDPT Admin',
    value: 'rdpt_admin'
  }
];

const apiName = 'surveyor-API';

export default function EditUserModal(props) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { userProfile, get } = props;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log('close');
    setOpen(false);
    get();
    // document.location.reload();
  };

  const editUser = async () => {
    const path = (user.role === 'rdpt_admin' ? '/admin/user' : '/user');
    const param = {
      body: {
        user_id: userProfile.ID,
        status: (document.getElementById('status').value !== '' ? document.getElementById('status').value : userProfile.status),
        first_name: (document.getElementById('first_name').value !== '' ? document.getElementById('first_name').value : userProfile.first_name),
        last_name: (document.getElementById('last_name').value !== '' ? document.getElementById('last_name').value : userProfile.last_name),
        // email: (document.getElementById('email').value !== '' ? document.getElementById('email').value : userProfile.email),
        role: (document.getElementById('roles').value !== '' ? document.getElementById('roles').value : userProfile.role)
      }
    };
    try {
      await API.patch(apiName, path, param);
      handleClose();
      enqueueSnackbar('User Updated!', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        autoHideDuration: 3000,
        variant: 'warning'
      });
    } catch (err) {
      handleClose();
      enqueueSnackbar('User Not Updated!', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        autoHideDuration: 3000,
        variant: 'warning'
      });
    }
  };

  const body = (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        minHeight: '100%',
        p: 3
      }}
    >
      <Paper
        elevation={8}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: 3,
          minHeight: 100,
          mx: 'auto',
          outline: 'none',
          width: 600
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            px: 2,
            py: 1
          }}
        >
          <Typography
            variant="h6"
            color="textPrimary"
          >
            Edit User
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton>
            <XIcon
              fontSize="small"
              onClick={handleClose}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            pb: 3,
            pt: 2,
            px: 2
          }}
        >
          <form onSubmit={(event) => event.preventDefault()}>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                disabled
                placeholder={userProfile.email}
                defaultValue={userProfile.email}
                label="Email"
                name="email"
                id="email"
                variant="outlined"
              />
            </Box>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    placeholder={userProfile.first_name}
                    defaultValue={userProfile.first_name}
                    label="First Name"
                    name="first_name"
                    id="first_name"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    placeholder={userProfile.last_name}
                    defaultValue={userProfile.last_name}
                    label="Last Name"
                    name="last_name"
                    id="last_name"
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    label="Status"
                    name="status"
                    id="status"
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                    defaultValue={userProfile.status}
                  >
                    {statusOptions.map((status) => (
                      <option
                        key={status.value}
                        value={status.value}
                      >
                        {status.label}
                      </option>
                    ))}
                  </TextField>
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Box sx={{ mt: 2 }}>
                  {user.role === 'rdpt_admin'
                    ? (
                      <TextField
                        fullWidth
                        label="Roles"
                        name="roles"
                        id="roles"
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        defaultValue={userProfile.role}
                      >
                        {rdptAdminRolesOptions.map((roles) => (
                          <option
                            key={roles.value}
                            value={roles.value}
                          >
                            {roles.label}
                          </option>
                        ))}
                      </TextField>
                    ) :
                    (
                      <TextField
                        fullWidth
                        label="Roles"
                        name="roles"
                        id="roles"
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        defaultValue={userProfile.role}
                      >
                        {rolesOptions.map((roles) => (
                          <option
                            key={roles.value}
                            value={roles.value}
                          >
                            {roles.label}
                          </option>
                        ))}
                      </TextField>
                    )}
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                color="secondary"
                sx={{ m: 2 }}
                type="submit"
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                sx={{ m: 2 }}
                type="submit"
                variant="contained"
                onClick={editUser}
              >
                Save User
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>
    </Box>
  );

  return (
    <div>
      <Tooltip title="Edit User">
        <IconButton
          size="small"
          onClick={handleOpen}
        >
          <PencilAlt />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
      >
        {body}
      </Modal>
    </div>
  );
}

EditUserModal.propTypes = {
  userProfile: PropTypes.array.isRequired
};
EditUserModal.propTypes = {
  get: PropTypes.func.isRequired
};
