import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { API } from 'aws-amplify';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import Label from '../../Label';
import Scrollbar from '../../Scrollbar';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const apiName = 'surveyor-API';

const applyPagination = (notifications, page, limit) => notifications
  .slice(page * limit, page * limit + limit);

function ScriptNotifications(props) {
  console.log('yoyo');
  const { user } = useAuth();
  const { scriptItems, ...other } = props;
  const [notifications, setNotifications] = useState([]);
  const isMountedRef = useIsMountedRef();
  const [p, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const path = (user.role === 'rdpt_admin' ? `/admin/notifications/script/${scriptItems.ID}` : `/notifications/script/${scriptItems.ID}`);
  const getNotifications = useCallback(async () => {
    try {
      let response = await API.get(apiName, path);
      if (response === null) {
        response = [];
      }
      if (isMountedRef.current) {
        setNotifications(response);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };
  const paginatedNotifications = applyPagination(notifications, p, limit);
  if (!notifications) {
    return null;
  }
  return (
    <Card {...other}>
      <CardHeader title="Notifications" />
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Message
                </TableCell>
                <TableCell>
                  Recommendations
                </TableCell>
                <TableCell>
                  Created
                </TableCell>
                <TableCell>
                  Last Modified
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedNotifications.map((notification) => (
                <TableRow key={notification.ID}>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {notification.message}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="left"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {notification.title}
                      </Typography>
                      <Typography
                        align="left"
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {notification.text}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell width={100}>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="center"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${notification.created_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                      </Typography>
                      <Typography
                        align="center"
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${notification.created_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell width={100}>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="center"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${notification.updated_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                      </Typography>
                      <Typography
                        align="center"
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${notification.updated_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {notification.client_viewed ? (
                      <Label color="success">
                        READ
                      </Label>
                    ) : (
                      <Label color="secondary">
                        NEW
                      </Label>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={notifications.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={p}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

ScriptNotifications.propTypes = {
  scriptItems: PropTypes.array.isRequired
};

export default ScriptNotifications;
