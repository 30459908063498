import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

function OverviewHosts(props) {
  const { hostdata } = props;

  return (
    <Card {...props}>
      <CardHeader
        sx={{ pb: 0 }}
        title={(
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Active Hosts
          </Typography>
        )}
      />
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ padding: 0 }}>
                  <Box sx={{ p: 1 }}>
                    <Typography
                      color="textSecondary"
                      variant="overline"
                    >
                      Host
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell style={{ padding: 0 }}>
                  <Box>
                    <Typography
                      color="textSecondary"
                      variant="overline"
                    >
                      Scripts
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell style={{ padding: 0 }}>
                  <Box>
                    <Typography
                      color="textSecondary"
                      variant="overline"
                    >
                      Score
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hostdata.sort((a, b) => a.hostname.localeCompare(b.hostname)).map((host) => (
                <TableRow>
                  <TableCell style={{ borderBottom: 'none', padding: 0 }}>
                      <Button
                        color="primary"
                        variant="text"
                        component={RouterLink}
                        to={`/dashboard/detail/host/${host.ID}`}
                      >
                        {host.hostname}
                      </Button>
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none', padding: 0 }}>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {host.script_count}
                      </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none', padding: 0 }}>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {host.score}
                      </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </CardContent>
    </Card>
  );
}

OverviewHosts.propTypes = {
  hostdata: PropTypes.array
};

export default OverviewHosts;
