import { Box, Grid, Typography, Card, LinearProgress } from '@mui/material';

function PageScore(passedscore) {
  const { score } = passedscore;
  return (
    <Grid container direction="row" justify="center" alignItems="flex-start" sx={{ minWidth: '20%' }}>
        <Card sx={{ pt: 0, pr: 2, pb: 0.5, pl: 2, width: '100%' }}>
          <Typography color="textSecondary" gutterBottom variant="overline"> Score </Typography>
          <Box sx={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap' }}>
            <Typography color="textPrimary" sx={{ mr: 1 }} variant="h5">
              {score}
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <LinearProgress color="primary" value={score} variant="determinate" />
            </Box>
          </Box>
        </Card>
    </Grid>
  );
}

export default PageScore;
