import Chart from 'react-apexcharts';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import ArrowRightIcon from '../../../icons/ArrowRight';
import InformationCircleIcon from '../../../icons/InformationCircle';

function OverviewAverageScript(props) {
  const { scriptsscore } = props;
  const theme = useTheme();

  const chart = {
    options: {
      chart: {
        background: 'transparent',
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: false
      },
      colors: ['#27c6db'],
      labels: [''],
      plotOptions: {
        radialBar: {
          dataLabels: {
            value: {
              show: false
            }
          },
          hollow: {
            size: '60%'
          },
          track: {
            background: theme.palette.background.default
          }
        }
      },
      theme: {
        mode: theme.palette.mode
      }
    },
    series: [scriptsscore]
  };

  return (
    <Card {...props}>
      <CardHeader
        disableTypography
        title={(
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Average Script Score
            </Typography>
            <Tooltip title="Average score of all scripts">
              <InformationCircleIcon fontSize="small" />
            </Tooltip>
          </Box>
        )}
      />
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Chart
          height={230}
          type="radialBar"
          {...chart}
        />
        <Box
          sx={{
            display: 'flex',
            flex: 1
          }}
        >
          <div>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              {Math.round(scriptsscore * 100) / 100}
            </Typography>
          </div>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          endIcon={<ArrowRightIcon fontSize="small" />}
          variant="text"
          component={RouterLink}
          to="/dashboard/detail/scripts"
        >
          All scripts
        </Button>
      </CardActions>
    </Card>
  );
}

OverviewAverageScript.propTypes = {
  scriptsscore: PropTypes.number
};

export default OverviewAverageScript;
