// import { useState } from 'react';
// import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
// import { useSnackbar } from 'notistack';
import {
  Button,
  Box,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Grid
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
// import useAuth from '../../../hooks/useAuth';
import InformationCircleIcon from '../../../icons/InformationCircle';
import Label from '../../Label';
import HostScripts from './HostScripts';
import HostDomains from './HostDomains';
import HostScriptSummary from './HostScriptSummary';
import HostXReqSummary from './HostXReqSummary';

// const apiName = 'surveyor-API';

function HostSummary(props) {
  const { host, ...other } = props;

  return (
    <Card {...other}>
      <CardHeader
        disableTypography
        title={(
          <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="textPrimary" variant="h6">
              Host Report
            </Typography>
            <Tooltip
              placement="left-start"
              title={(
                <>
                  <Typography color="inherit">About Script Details</Typography>
                  <Table width={600}>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="h6"> Score </Typography>
                      </TableCell>
                      <TableCell> The score is based on its malicious rating determined by machine learning, does the script have known vulnerabilities and is the script served over TLS. The score is based out of 100.  </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label color="success"> Active </Label>
                      </TableCell>
                      <TableCell> Surveyor is monitoring this host </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="h6"> Type </Typography>
                      </TableCell>
                      <TableCell>
                        <b>Inline</b>
                        - javascript that is contained within a page.
                        <Divider />
                        <b>Included</b>
                        - a javascript file that is included either from the host or externally
                      </TableCell>
                    </TableRow>
                  </Table>
                </>
              )}
            >
              <InformationCircleIcon fontSize="small" />
            </Tooltip>
          </Box>
        )}
      />
      <Divider />

      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell width="20%">
              <Typography color="textPrimary" variant="subtitle2"> Hostname </Typography>
            </TableCell>
            <TableCell width="80%">
              <Typography align="left" color="textPrimary" variant="subtitle2">
                {host.hostname}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2"> Observed </Typography>
            </TableCell>
            <TableCell>
              <Typography align="left" color="textPrimary" variant="subtitle2">
                {host.first_seen}
                &nbsp;-&nbsp;
                {host.last_seen}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <HostScriptSummary host={host} />
                </Grid>
                <Grid item xs={6}>
                  <HostXReqSummary host={host} />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Script Source(s) (
                  {Object.keys(host.sources.script).length}
                )
              </Typography>
            </TableCell>
            <TableCell>
              <HostScripts host={host} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Network Sources (
                  {Object.keys(host.sources.xrequest).length}
                )
              </Typography>
            </TableCell>
            <TableCell>
              <Box sx={{ p: 1 }}>
                <HostDomains host={host} />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width="33%">
              <Typography color="textPrimary" variant="subtitle2"> Host Pages </Typography>
            </TableCell>
            <TableCell width="67%">
              <Table>
                <TableBody>
                  {host.pages.map((page) => (
                    <TableRow
                      hover
                      key={page}
                    >
                      <TableCell align="left">
                        <Typography color="textPrimary" variant="subtitle2">
                        {page}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardActions>
        <Button color="primary" startIcon={<BuildIcon fontSize="small" />} variant="text"/* onClick={handleReanalyze} */>
          Reanalyze Script
        </Button>
      </CardActions>
    </Card>
  );
}

HostSummary.propTypes = {
  // @ts-ignore
  host: PropTypes.object.isRequired
};

export default HostSummary;
