import PropTypes from 'prop-types';
import { useState } from 'react';
import numeral from 'numeral';
import { format } from 'date-fns';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import Scrollbar from '../../Scrollbar';

const applyPagination = (xrequests, page, limit) => xrequests
  .slice(page * limit, page * limit + limit);

function PageXRequests(props) {
  const { xrequests, ...other } = props;
  const [p, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const handlePageChange = (xrequest, newPage) => {
    setPage(newPage);
  };

  console.log('xrequests');
  console.log(xrequests);
  const handleLimitChange = (xrequest) => {
    setLimit(parseInt(xrequest.target.value, 10));
  };
  const paginatedXRequests = applyPagination(xrequests, p, limit);
  if (!xrequests) {
    return null;
  }
  return (
    <Card {...other}>
      <CardHeader title="Observed Network Connection Requests" />
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={150}>
                  Page/URL
                </TableCell>
                <TableCell width={100}>
                  Last Seen
                </TableCell>
                <TableCell width={100}>
                  First Seen
                </TableCell>
                <TableCell width={100}>
                  Score
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody align="left">
              {paginatedXRequests.map((xrequest) => (
                <TableRow key={xrequest.ID}>
                  <TableCell width={150}>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {xrequest.url}
                    </Typography>
                  </TableCell>
                  <TableCell width={100}>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="left"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${xrequest.modified_at.replace(' ', 'T')}+0000`), 'MM-dd-yyyy').toUpperCase()}
                      </Typography>
                      <Typography
                        align="left"
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${xrequest.modified_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell width={100}>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="left"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${xrequest.created_at.replace(' ', 'T')}+0000`), 'MM-dd-yyyy').toUpperCase()}
                      </Typography>
                      <Typography
                        align="left"
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${xrequest.created_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell width={100}>
                    {xrequest.score != null ? numeral(xrequest.score).format(`${xrequest.score}`) : 'n/a'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={xrequests.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={p}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

PageXRequests.propTypes = {
  xrequests: PropTypes.array.isRequired
};

export default PageXRequests;
