import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReactLoading from 'react-loading';
import { useState, useEffect, useCallback } from 'react';
import { Grid, Button, TextareaAutosize, Typography } from '@mui/material';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const apiName = 'surveyor-API';

function TemplateCode(props) {
  const { templateid } = props;
  const isMountedRef = useIsMountedRef();
  const type = 'spinningBubbles';
  const color = '#688eff';
  const path = (`/scan/template/${templateid}`);

  const [code, setCode] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [editing, setEditing] = useState(false);

  const getCode = useCallback(async () => {
    try {
      const response = await API.get(apiName, path);
      if (isMountedRef.current) {
        setCode(response.template);
        setTemplateName(response.name.replaceAll(' ', '_'));
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = () => {
    try {
      const options = {
        body: {
          template: btoa(code),
          template_name: templateName
        }
      };
      API.put(apiName, `/scan/template/${templateid}`, options);
    } catch (err) {
      console.error('Failed to save template');
      console.error(err);
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  useEffect(() => {
    getCode();
  }, [getCode]);

  if (!code) {
    return (
      <ReactLoading type={type} color={color} height={1055} width={100} />
    );
  }
  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid item sm={9} md={9} lg={10} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
        <Typography color="textPrimary" variant="h6" sx={{ lineHeight: 2 }}>{templateName}</Typography>
      </Grid>
      <Grid item sm={3} md={3} lg={2} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        {editing ? (
          <>
            <Button color="secondary" size="medium" sx={{ ml: 1 }} variant="outlined" onClick={() => handleCancel()}>Cancel</Button>
            <Button color="primary" size="medium" sx={{ ml: 1 }} variant="outlined" onClick={() => handleSave()}>Save</Button>
          </>
        ) : null}
        {!editing ? (
          <Button color="primary" size="medium" sx={{ ml: 1 }} variant="outlined" onClick={() => handleEdit()}>Edit</Button>
        ) : null}
      </Grid>
      {editing ? (
        <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
          <TextareaAutosize value={code} onChange={(event) => setCode(event.target.value)} style={{ width: '100%', padding: '1em', marginTop: '1em' }} />
        </Grid>
      ) : null}
      {!editing ? (
        <Grid item sx={{ width: '100%' }}>
          <SyntaxHighlighter {...props} language="javascript" style={vscDarkPlus} wrapLongLines showInlineLineNumbers>
            {code}
          </SyntaxHighlighter>
        </Grid>
      ) : null}
    </Grid>
  );
}

TemplateCode.propTypes = {
  // @ts-ignore
  templateid: PropTypes.string.isRequired
};

export default TemplateCode;
