import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import Label from '../../Label';
import ArrowRightIcon from '../../../icons/ArrowRight';
import SearchIcon from '../../../icons/Search';
import TrashIcon from '../../../icons/Trash';
import Archive from '../../../icons/Archive';
import Scrollbar from '../../Scrollbar';

const apiName = 'surveyor-API';

const tabs = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'New',
    value: 'isNew'
  },
  {
    label: 'Read',
    value: 'isRead'
  },
  {
    label: 'Informational',
    value: 'isInfo'
  },
  {
    label: 'Low',
    value: 'isLow'
  },
  {
    label: 'Medium',
    value: 'isMedium'
  },
  {
    label: 'High',
    value: 'isHigh'
  },
  {
    label: 'Critical',
    value: 'isCritical'
  }

];

const sortOptions = [
  {
    label: 'Created Date (newest)',
    value: 'createdAt|desc'
  },
  {
    label: 'Created Date (oldest)',
    value: 'createdAt|asc'
  },
  {
    label: 'Severity (highest)',
    value: 'severityScore|desc'
  },
  {
    label: 'Severity (lowest)',
    value: 'severityScore|asc'
  }
];

const applyFilters = (notifications, query, filters) => notifications
  .filter((notification) => {
    let matches = true;
    if (query) {
      const properties = ['message', 'hostname', 'severity'];
      let containsQuery = false;
      properties.forEach((property) => {
        if (notification[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && notification[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (notifications, page, limit) => notifications
  .slice(page * limit, page * limit + limit);

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const applySort = (notifications, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = notifications.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

function NotificationListTable(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { notifications, ...other } = props;
  const [currentTab, setCurrentTab] = useState('all');
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  const [filters, setFilters] = useState({
    isNew: null,
    isRead: null,
    isInfo: null,
    isLow: null,
    isMedium: null,
    isHigh: null,
    isCritical: null
  });

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      isRead: null,
      isNew: null,
      isInfo: null,
      isLow: null,
      isMedium: null,
      isHigh: null,
      isCritical: null
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }

    setFilters(updatedFilters);
    setSelectedNotifications([]);
    setCurrentTab(value);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleSelectAllNotifications = (event) => {
    setSelectedNotifications(event.target.checked
      ? notifications.map((notification) => notification.ID)
      : []);
  };

  const handleSelectOneNotification = (event, notificationId) => {
    if (!selectedNotifications.includes(notificationId)) {
      setSelectedNotifications((prevSelected) => [...prevSelected, notificationId]);
    } else {
      setSelectedNotifications((prevSelected) => prevSelected.filter((id) => id !== notificationId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleMarkSelected = async () => {
    const notificationsPath = '/notifications';
    const params = {
      body: {
        notification_ids: selectedNotifications,
        viewed: 1
      }
    };
    const response = await API.post(apiName, notificationsPath, params);
    if (response) {
      enqueueSnackbar(`${selectedNotifications.length} Notifications marked as read!`, {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        autoHideDuration: 5000,
        variant: 'success'
      });
      console.log(`${selectedNotifications.length} Notifications marked as read`);
      notifications.forEach((notification) => {
        selectedNotifications.forEach((selectedNotification) => {
          if (notification.ID === selectedNotification) {
            notification.isNew = false;
            notification.client_viewed = true;
            notification.isRead = true;
          }
        });
      });
      setSelectedNotifications([]);
    }
  };

  const handleDeleteSelected = async () => {
    const notificationsPath = '/notifications';
    const params = {
      body: {
        notification_ids: selectedNotifications
      }
    };
    const response = await API.del(apiName, notificationsPath, params);
    if (response) {
      enqueueSnackbar(`${selectedNotifications.length} Notifications deleted!`, {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        autoHideDuration: 5000,
        variant: 'success'
      });
      console.log(`${selectedNotifications.length} notifications deleted`);
      notifications.forEach((notification, index) => {
        selectedNotifications.forEach((selectedNotification) => {
          if (notification.ID === selectedNotification) {
            notification.isNew = false;
            notification.client_viewed = true;
            notification.isRead = true;
            delete notifications[index];
          }
        });
      });
      setSelectedNotifications([]);
    }
  };
  const filteredNotifications = applyFilters(notifications, query, filters);
  const sortedNotifications = applySort(filteredNotifications, sort);
  const paginatedNotifications = applyPagination(sortedNotifications, page, limit);
  const enableBulkActions = selectedNotifications.length > 0;
  const selectedSomeNotifications = selectedNotifications.length > 0
    && selectedNotifications.length < notifications.length;
  const selectedAllNotifications = selectedNotifications.length === notifications.length;

  const handleDelete = async (notificationID) => {
    const notificationsPath = '/notifications';
    const params = {
      body: {
        notification_id: notificationID
      }
    };
    const response = API.del(apiName, notificationsPath, params);
    if (response) {
      console.log(`${notificationID} notification deleted`);
      let toDelete = -1;
      notifications.forEach((notification, index) => {
        if (notification.ID === notificationID) {
          toDelete = index;
        }
      });
      if (toDelete !== -1) {
        // document.getElementById(notificationID).style.display = 'none';
        delete notifications[toDelete];
        enqueueSnackbar('Notification deleted!', {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          autoHideDuration: 5000,
          variant: 'success'
        });
      }
      setSelectedNotifications([]);
    }
  };

  const handleMarkAsRead = async (notificationID) => {
    const notificationsPath = '/notifications';
    const params = {
      body: {
        notification_id: notificationID,
        viewed: 1
      }
    };
    const response = API.post(apiName, notificationsPath, params);
    if (response) {
      enqueueSnackbar('Notification marked as read!', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        autoHideDuration: 5000,
        variant: 'success'
      });
      console.log(`${notificationID} marked as read`);
      notifications.forEach((notification) => {
        if (notification.ID === notificationID) {
          notification.client_viewed = true;
          notification.isNew = false;
          notification.isRead = true;
        }
      });
      setSelectedNotifications([]);
    }
  };
  return (
    <Card {...other}>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Search notifications"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            width: 240
          }}
        >
          <TextField
            label="Sort By"
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
            value={sort}
            variant="outlined"
          >
            {sortOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>
      </Box>
      {enableBulkActions && (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              mt: '6px',
              position: 'absolute',
              px: '4px',
              width: '100%',
              zIndex: 2
            }}
          >
            <Checkbox
              checked={selectedAllNotifications}
              color="primary"
              indeterminate={selectedSomeNotifications}
              onChange={handleSelectAllNotifications}
            />
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
              onClick={() => handleMarkSelected()}
            >
              Mark Selected as Read
            </Button>
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
              onClick={() => handleDeleteSelected()}
            >
              Delete Selected
            </Button>
          </Box>
        </Box>
      )}
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllNotifications}
                    color="primary"
                    indeterminate={selectedSomeNotifications}
                    onChange={handleSelectAllNotifications}
                  />
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Created
                </TableCell>
                <TableCell>
                  Severity
                </TableCell>
                <TableCell>
                  Message
                </TableCell>
                <TableCell>
                  Recommendations
                </TableCell>
                <TableCell align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedNotifications.map((notification) => {
                const isNotificationSelected = selectedNotifications.includes(notification.ID);

                return (
                  <TableRow
                    hover
                    key={notification.ID}
                    id={notification.ID}
                    selected={isNotificationSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isNotificationSelected}
                        color="primary"
                        onChange={(event) => handleSelectOneNotification(event, notification.ID)}
                        value={isNotificationSelected}
                      />
                    </TableCell>
                    <TableCell width={100}>
                      <Label
                        color={notification.client_viewed ? 'primary' : 'error'}
                      >
                        {notification.client_viewed ? 'READ' : 'NEW'}
                      </Label>
                    </TableCell>
                    <TableCell width={100}>
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="center"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {/* {!notification.client_viewed ? 'NEW' : ''} */}
                          {format(new Date(`${notification.created_at.replace(' ', 'T')}.000+08:00`), 'LLL d').toUpperCase()}
                        </Typography>
                        <Typography
                          align="center"
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {format(new Date(`${notification.created_at.replace(' ', 'T')}.000+08:00`), 'HH:mm:ss')}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell width={100}>
                      <Label color={notification.severityColor}>
                        {notification.severity.toUpperCase()}
                      </Label>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color="inherit"
                            variant="subtitle2"
                          >
                            {notification.message}
                          </Link>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            {notification.hostname}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="left"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {notification.title_rec}
                        </Typography>
                        <Typography
                          align="left"
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {notification.text_rec}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={notification.item_type === 'xrequest' ? `/dashboard/detail/page/${notification.url_id}` : `/dashboard/detail/script/${notification.item_id}`}
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(notification.ID)}>
                        <TrashIcon fontSize="small" />
                      </IconButton>
                      {notification.isNew
                        ? (
                          <IconButton onClick={() => handleMarkAsRead(notification.ID)}>
                            <Archive fontSize="small" />
                          </IconButton>
                        )
                        : ''}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={filteredNotifications.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

NotificationListTable.propTypes = {
  notifications: PropTypes.array.isRequired
};

export default NotificationListTable;
