import { API } from 'aws-amplify';
import { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Card,
  FormControlLabel,
  IconButton,
  // InputAdornment,
  // Link
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  // TextField,
  Typography
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import EditUserModal from '../../widgets/modals/EditUserModal';
import AddUserModal from '../../widgets/modals/AddUserModal';
import Scrollbar from '../../Scrollbar';
import TrashIcon from '../../../icons/Trash';
// import SearchIcon from '../../../icons/Search';
// import PencilAlt from '../../../icons/PencilAlt';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// const sortOptions = [
//   {
//     label: 'User (A-Z)',
//     value: 'email|asc'
//   },
//   {
//     label: 'User (Z-A)',
//     value: 'email|desc'
//   }
// ];
const apiName = 'surveyor-API';

function UserListTable() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const [users, setUsers] = useState([]);
  const usersPath = (user.role === 'rdpt_admin' ? '/admin/user/list' : '/user/list');
  console.log(user);
  const getUsers = useCallback(async () => {
    try {
      const response = await API.get(apiName, usersPath);
      console.log(response);
      if (isMountedRef.current) {
        setUsers(response);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getUsers();
  }, []);

  const handleChange = async (ID, status) => {
    const h = [...users];
    if (status === 'active') {
      status = 'inactive';
    } else {
      status = 'active';
    }
    const path = '/user';
    const param = {
      body: {
        user_id: ID,
        status
      }
    };
    const response = await API.patch(apiName, path, param);
    if (response) {
      h.forEach((_user) => {
        if (_user.ID === ID) {
          _user.status = status;
          enqueueSnackbar(`${_user.email} set to ${status}!`, {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            autoHideDuration: 5000,
            variant: 'success'
          });
        }
      });
    }
    setUsers(h);
  };

  const handleDelete = async (ID) => {
    const path = '/user';
    const h = [...users];
    const param = {
      body: {
        user_id: ID
      }
    };
    try {
      const response = await API.del(apiName, path, param);
      let k = -1;
      console.log(response);
      if (response) {
        h.forEach((_user, index) => {
          if (_user.ID === ID) {
            k = index;
            enqueueSnackbar(`${_user.email.toUpperCase()} Deleted!`, {
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
              },
              autoHideDuration: 5000,
              variant: 'success'
            });
          }
        });
        delete h[k];
        setUsers(h);
      }
    } catch (err) {
      enqueueSnackbar('User Not Deleted!', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        autoHideDuration: 3000,
        variant: 'warning'
      });
    }
    // document.location.reload();
  };
  return (
    <Box>
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography color="textPrimary" variant="h5">
            Users
          </Typography>
        </Grid>
        <Grid item>
          <Box sx={{ mb: 2, alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
            <AddUserModal get={getUsers} />
          </Box>
        </Grid>
      </Grid>
      <Card>
        {/* for user search and sort
        <Box sx={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', m: -1, p: 2 }}>
          <Box sx={{ m: 1, maxWidth: '100%', width: 500 }}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
              placeholder="Search users"
              variant="outlined"
            />
          </Box>
          <Box sx={{ m: 1, maxWidth: '100%', width: 240 }}>
            <TextField label="Sort By" name="sort" select SelectProps={{ native: true }} variant="outlined">
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
        </Box>
        End user search and sort */}
        <Scrollbar>
          <Box>
            <Table sx={{ mt: 2 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Email
                  </TableCell>
                  {user.role === 'rdpt_admin'
                    ? (
                      <TableCell>
                        Company
                      </TableCell>
                    )
                    :
                    ''}
                  <TableCell>
                    First Name
                  </TableCell>
                  <TableCell>
                    Last Name
                  </TableCell>
                  <TableCell>
                    Role
                  </TableCell>
                  <TableCell>
                    Active
                  </TableCell>
                  <TableCell
                    align="center"
                    width={150}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((_user) => (
                  <TableRow hover key={_user.ID}>
                    <TableCell>
                      <Typography align="left" color="textPrimary" variant="subtitle">
                        {_user.email}
                      </Typography>
                    </TableCell>
                    {user.role === 'rdpt_admin'
                      ? (
                        <TableCell>
                          <Typography align="left" color="textPrimary" variant="subtitle">
                            {_user.name}
                          </Typography>
                        </TableCell>
                      )
                      :
                      ''}
                    <TableCell>
                      <Typography align="left" color="textPrimary" variant="subtitle">
                        {_user.first_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left" color="textPrimary" variant="subtitle">
                        {_user.last_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left" color="textPrimary" variant="subtitle">
                        {_user.role !== null ? _user.role.toUpperCase() : 'Null'}
                      </Typography>
                    </TableCell>
                    <TableCell
                      onClick={() => handleChange(_user.ID, _user.status)}
                    >
                      <FormControlLabel
                        control={(
                          <Switch
                            color="primary"
                            checked={_user.status === 'active'}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="right" width={150}>
                      {user.company === _user.name || user.role === 'admin'
                        ? (
                          <>
                            <IconButton>
                              <EditUserModal
                                userProfile={_user}
                                get={getUsers}
                              />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(_user.ID)}>
                              <TrashIcon fontSize="small" />
                            </IconButton>
                          </>
                        ) :
                        ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={users.length}
              onPageChange={() => {
              }}
              onRowsPerPageChange={() => {
              }}
              page={0}
              rowsPerPage={5}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Box>
        </Scrollbar>
      </Card>
    </Box>
  );
}

export default UserListTable;
