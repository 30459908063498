import { API } from 'aws-amplify';
import { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Card,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Typography,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import Scrollbar from '../../Scrollbar';
import TrashIcon from '../../../icons/Trash';
import SearchIcon from '../../../icons/Search';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const sortOptions = [
  {
    label: 'Client (A-Z)',
    value: 'name|asc'
  },
  {
    label: 'Client (Z-A)',
    value: 'name|desc'
  }
];
const apiName = 'surveyor-API';

function ClientListTable() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const [clients, setClients] = useState([]);
  const clientsPath = (user.role === 'rdpt_admin' ? '/admin/clients' : '/clients');
  const getClients = useCallback(async () => {
    try {
      const response = await API.get(apiName, clientsPath);
      if (isMountedRef.current) {
        setClients(response);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getClients();
  }, []);

  const handleChange = async (ID, status) => {
    const h = [...clients];
    if (status === 'active') {
      status = 'inactive';
    } else {
      status = 'active';
    }
    const path = '/admin/clients';
    const param = {
      body: {
        client_id: ID,
        status
      }
    };
    const response = await API.put(apiName, path, param);
    console.log(response);
    if (response !== 'Client Not Updated!') {
      h.forEach((client) => {
        if (client.ID === ID) {
          client.status = status;
          enqueueSnackbar(`${client.name} set to ${status}!`, {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            autoHideDuration: 5000,
            variant: 'success'
          });
        }
      });
    } else {
      enqueueSnackbar(response, {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        autoHideDuration: 5000,
        variant: 'error'
      });
    }
    setClients(h);
  };

  const handleDelete = async (ID) => {
    console.log(ID);
    enqueueSnackbar("We aren't doing this yet", {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      },
      autoHideDuration: 5000,
      variant: 'error'
    });
    // const path = '/admin/clients';
    // const h = [...clients];
    // const param = {
    //   body: {
    //     ID
    //   }
    // };
    // const response = await API.del(apiName, path, param);
    // let k = -1;
    // if (response) {
    //   h.forEach((client, index) => {
    //     if (client.ID === ID) {
    //       k = index;
    //       enqueueSnackbar(`${client.name.toUpperCase()} Deleted!`, {
    //         anchorOrigin: {
    //           horizontal: 'right',
    //           vertical: 'top'
    //         },
    //         autoHideDuration: 5000,
    //         variant: 'success'
    //       });
    //     }
    //   });
    // }
    // delete h[k];
    // console.log(h);
    // setClients(h);
    // // document.location.reload();
  };
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3
      }}
    >
      <Card>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            m: -1,
            p: 2
          }}
        >
          <Box
            sx={{
              m: 1,
              maxWidth: '100%',
              width: 500
            }}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
              placeholder="Search clients"
              variant="outlined"
            />
          </Box>
          <Box
            sx={{
              m: 1,
              maxWidth: '100%',
              width: 240
            }}
          >
            <TextField
              label="Sort By"
              name="sort"
              select
              SelectProps={{ native: true }}
              variant="outlined"
            >
              {sortOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
        </Box>
        <Scrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Client
                  </TableCell>
                  <TableCell>
                    ID
                  </TableCell>
                  <TableCell>
                    Billing Email
                  </TableCell>
                  <TableCell>
                    Type
                  </TableCell>
                  <TableCell>
                    Plan
                  </TableCell>
                  <TableCell>
                    Stripe ID
                  </TableCell>
                  <TableCell>
                    Active
                  </TableCell>
                  <TableCell align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.map((client) => (
                  <TableRow
                    hover
                    key={client.ID}
                  >
                    <TableCell width={100}>
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="center"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {client.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {client.ID}
                    </TableCell>
                    <TableCell>
                      {client.billing_email}
                    </TableCell>
                    <TableCell>
                      {client.type}
                    </TableCell>
                    <TableCell>
                      {client.plan}
                    </TableCell>
                    <TableCell>
                      {client.stripe_id}
                    </TableCell>
                    <TableCell
                      onClick={() => handleChange(client.ID, client.status)}
                    >
                      <Box sx={{ m: 2 }}>
                        <FormControlLabel
                          control={(
                            <Switch
                              color="primary"
                              checked={client.status === 'active'}
                            />
                          )}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleDelete(client.ID)}>
                        <TrashIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={clients.length}
              onPageChange={() => {
              }}
              onRowsPerPageChange={() => {
              }}
              page={0}
              rowsPerPage={5}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Box>
        </Scrollbar>
      </Card>
    </Box>
  );
}

export default ClientListTable;
