import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { API } from 'aws-amplify';
import { Box, Breadcrumbs, Container, Grid, Link, Typography, Tab, Tabs, Divider } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { HostSummary } from '../../components/dashboard/detail';
// import useIsMountedRef from '../../hooks/useIsMountedRef';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';

const tabs = [
  { label: 'Summary', value: 'summary' }
];

const apiName = 'surveyor-API';

function HostDetails() {
  const { user } = useAuth();
  // const isMountedRef = useIsMountedRef();
  const { settings } = useSettings();
  const [host, setHost] = useState(null);
  const { hostId } = useParams();
  const [currentTab, setCurrentTab] = useState('summary');
  const path = (user.role === 'rdpt_admin' ? `/admin/host/${hostId}` : `/host/${hostId}`);
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(async () => {
    try {
      const response = await API.get(apiName, path);
      // response.sources.script.sort((a, b) => a.localeCompare(b));
      // response.sources.xrequest.sort((a, b) => a.localeCompare(b));
      response.pages.sort((a, b) => a.localeCompare(b));
      await setHost(response);
    } catch (err) {
      console.error(err);
    }
  }, []);

  if (!host) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard: Host Details | Surveyor</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {host.hostname}
              </Typography>
              <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
                <Link color="textPrimary" component={RouterLink} to="/dashboard" variant="subtitle2">
                  Dashboard
                </Link>
                <Link color="textPrimary" component={RouterLink} to="/dashboard/detail/hosts" variant="subtitle2">
                  Hosts
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  {host.ID}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item md={12} xl={12} xs={12}>
                <Box sx={{ mt: 3 }}>
                  <Tabs
                    indicatorColor="primary"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    textColor="primary"
                    value={currentTab}
                    variant="scrollable"
                  >
                    {tabs.map((tab) => (
                      <Tab key={tab.value} label={tab.label} value={tab.value} />
                    ))}
                  </Tabs>
                </Box>
                <Divider />
                <Box sx={{ mt: 3 }}>
                  {currentTab === 'summary' && <HostSummary host={host} />}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default HostDetails;
