import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  CardActions,
  Button,
  Divider
} from '@mui/material';
import PropTypes from 'prop-types';
import ArrowRightIcon from '../../../icons/ArrowRight';

function OverviewLatestScripts(props) {
  const { scriptsdata } = props;
  return (
    <Card {...props}>
      <CardHeader title="Latest Scripts" />
      <Divider />
      <Table>
        <TableBody>
          {scriptsdata.map((script) => (
            <TableRow
              key={script.ID}
              sx={{
                '&:last-child td': {
                  border: 0
                }
              }}
            >
              <TableCell width={100}>
                <Box sx={{ p: 1 }}>
                  <Typography
                    align="center"
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {format(new Date(`${script.last_seen.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                  </Typography>
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    {format(new Date(`${script.last_seen.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <div>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {script.script_url
                      ?
                      script.script_url
                      :
                      (
                        <SyntaxHighlighter
                          {...props}
                          language="javascript"
                          style={vscDarkPlus}
                          wrapLongLines
                          showInlineLineNumbers
                        >
                          {script.first_line}
                        </SyntaxHighlighter>
                      )}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {script.hostname}
                    {' '}
                    -
                    {' '}
                    {script.script_url ? 'included' : 'inline'}
                  </Typography>
                </div>
              </TableCell>
              <TableCell align="right">
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Score
                </Typography>
                <Typography
                  color={script.score > 10 ? 'textWarning' : 'textSecondary'}
                  variant="body2"
                >
                  {script.score}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <IconButton
                  component={RouterLink}
                  to={`/dashboard/detail/script/${script.ID}`}
                >
                  <ArrowRightIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CardActions
        sx={{
          px: 2,
          py: 1.5,
          backgroundColor: 'background.default'
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon fontSize="small" />}
          variant="text"
          component={RouterLink}
          to="/dashboard/detail/scripts"
        >
          See all scripts
        </Button>
      </CardActions>
    </Card>
  );
}

OverviewLatestScripts.propTypes = {
  scriptsdata: PropTypes.array
};

export default OverviewLatestScripts;
