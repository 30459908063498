import PropTypes from 'prop-types';
import { format } from 'date-fns';
import numeral from 'numeral';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
// import Label from '../../Label';
import InformationCircleIcon from '../../../icons/InformationCircle';

function PageSummary(props) {
  const { url, ...other } = props;

  return (
    <Card {...other}>
      <CardHeader
        disableTypography
        title={(
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Page Info
            </Typography>
            <Tooltip
              placement="left-start"
              title={(
                <>
                  <Typography color="inherit">About Page Details</Typography>
                  <Table width={600}>
                    <TableRow>
                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="h6"
                        >
                          Score
                        </Typography>
                      </TableCell>
                      <TableCell>
                        The score is based on the aggregate scores of the javascript the page contains(20%), if it contains protected fields(10%), cookies(10%), forms/input fields(10%) and if the page is loaded over https(50%). The score is out of 100 and the higher the number the greater the risk.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="h6"
                        >
                          Protected Fields
                        </Typography>
                      </TableCell>
                      <TableCell>
                        The use of the password attribute on an input field
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="h6"
                        >
                          Forms
                        </Typography>
                      </TableCell>
                      <TableCell>
                        The use of the form html tag.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width={20}>
                        <Typography
                          color="textPrimary"
                          variant="h6"
                        >
                          Cookies
                        </Typography>
                      </TableCell>
                      <TableCell>
                        Cookies are not gathered, only report the existence of cookies if able to detect them
                      </TableCell>
                    </TableRow>
                  </Table>
                </>
              )}
            >
              <InformationCircleIcon fontSize="small" />
            </Tooltip>
          </Box>
        )}
      />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Score
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {url.score}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                URL
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {url.full_url}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                First Seen Date
              </Typography>
            </TableCell>
            <TableCell>
              <Box sx={{ p: 1 }}>
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {format(new Date(`${url.created_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                </Typography>
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {format(new Date(`${url.created_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Last Seen Date
              </Typography>
            </TableCell>
            <TableCell>
              <Box sx={{ p: 1 }}>
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {format(new Date(`${url.modified_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                </Typography>
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {format(new Date(`${url.modified_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Protected Fields
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {url.protected_fields}
              </Typography>
            </TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Number of times this script was seen
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {numeral(url.event_count)
                  .format(`${url.event_count}0,0`)}
              </Typography>
            </TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Number of scripts with this page
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {numeral(url.scriptCount)
                  .format(`${url.scriptCount}0,0`)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Forms on page
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {numeral(url.forms)
                  .format(`${url.forms}0,0`)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Cookies present
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {url.cookies > 0 ? 'True' : 'False'}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
}

PageSummary.propTypes = {
  // @ts-ignore
  url: PropTypes.object.isRequired
};

export default PageSummary;
