import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import Label from '../../Label';
import ArrowRightIcon from '../../../icons/ArrowRight';
import SearchIcon from '../../../icons/Search';
import Scrollbar from '../../Scrollbar';

const tabs = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Inline',
    value: 'isInline'
  },
  {
    label: 'Included',
    value: 'isIncluded'
  },
  {
    label: 'Has Findings',
    value: 'hasFindings'
  }
];

const sortOptions = [
  {
    label: 'Last Seen (newest)',
    value: 'updatedAt|desc'
  },
  {
    label: 'Last Seen (oldest)',
    value: 'updatedAt|asc'
  },
  {
    label: 'First Seen (newest)',
    value: 'createdAt|desc'
  },
  {
    label: 'First Seen (oldest)',
    value: 'createdAt|asc'
  },
  {
    label: 'Scores (highest)',
    value: 'score|desc'
  },
  {
    label: 'Scores (lowest)',
    value: 'score|asc'
  },
  {
    label: 'Total Findings (highest)',
    value: 'totalFindings|desc'
  },
  {
    label: 'Total Findings (lowest)',
    value: 'totalFindings|asc'
  }
];

const applyFilters = (scripts, query, filters) => scripts
  .filter((script) => {
    let matches = true;
    if (query) {
      const properties = ['script', 'hostname'];
      let containsQuery = false;
      properties.forEach((property) => {
        if (script[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && script[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (scripts, page, limit) => scripts
  .slice(page * limit, page * limit + limit);

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const applySort = (scripts, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = scripts.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

function ScriptListTable(props) {
  const { scripts, ...other } = props;
  const [currentTab, setCurrentTab] = useState('all');
  const [selectedScripts, setSelectedScripts] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  const [filters, setFilters] = useState({
    isInline: null,
    isIncluded: null,
    hasFindings: null
  });

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      isInline: null,
      isIncluded: null,
      hasFindings: null
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }

    setFilters(updatedFilters);
    setSelectedScripts([]);
    setCurrentTab(value);
    setPage(0);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleSelectAllScripts = (event) => {
    setSelectedScripts(event.target.checked
      ? scripts.map((script) => script.ID)
      : []);
  };

  // const handleSelectOneScript = (event, scriptId) => {
  //   if (!selectedScripts.includes(scriptId)) {
  //     setSelectedScripts((prevSelected) => [...prevSelected, scriptId]);
  //   } else {
  //     setSelectedScripts((prevSelected) => prevSelected.filter((id) => id !== scriptId));
  //   }
  // };

  const handlePageChange = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const filteredScripts = applyFilters(scripts, query, filters);
  const sortedScripts = applySort(filteredScripts, sort);
  const paginatedScripts = applyPagination(sortedScripts, page, limit);
  const enableBulkActions = selectedScripts.length > 0;
  const selectedSomeScripts = selectedScripts.length > 0
    && selectedScripts.length < scripts.length;
  const selectedAllScripts = selectedScripts.length === scripts.length;

  return (
    <Card {...other}>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Search scripts"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            width: 240
          }}
        >
          <TextField
            label="Sort By"
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
            value={sort}
            variant="outlined"
          >
            {sortOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>
      </Box>
      {enableBulkActions && (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              mt: '6px',
              position: 'absolute',
              px: '4px',
              width: '100%',
              zIndex: 2
            }}
          >
            <Checkbox
              checked={selectedAllScripts}
              color="primary"
              indeterminate={selectedSomeScripts}
              onChange={handleSelectAllScripts}
            />
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Delete
            </Button>
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Edit
            </Button>
          </Box>
        </Box>
      )}
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllScripts}
                    color="primary"
                    indeterminate={selectedSomeScripts}
                    onChange={handleSelectAllScripts}
                  />
                </TableCell> */}
                <TableCell>
                  Last Seen
                </TableCell>
                <TableCell>
                  First Seen
                </TableCell>
                <TableCell>
                  Script
                </TableCell>
                <TableCell>
                  Findings
                </TableCell>
                <TableCell>
                  Score
                </TableCell>
                <TableCell align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedScripts.map((script) => {
                const isScriptSelected = selectedScripts.includes(script.ID);

                return (
                  <TableRow
                    hover
                    key={script.ID}
                    selected={isScriptSelected}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={isScriptSelected}
                        color="primary"
                        onChange={(event) => handleSelectOneScript(event, script.ID)}
                        value={isScriptSelected}
                      />
                    </TableCell> */}
                    <TableCell width={100}>
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="center"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {format(new Date(`${script.last_seen.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                        </Typography>
                        <Typography
                          align="center"
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {format(new Date(`${script.last_seen.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell width={100}>
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="center"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {format(new Date(`${script.created_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                        </Typography>
                        <Typography
                          align="center"
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {format(new Date(`${script.created_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color="inherit"
                            variant="subtitle2"
                          >
                            {script.isInline
                              ? (
                                <SyntaxHighlighter
                                  {...props}
                                  language="javascript"
                                  style={vscDarkPlus}
                                  wrapLongLines
                                  showInlineLineNumbers
                                >
                                  {script.script}
                                </SyntaxHighlighter>
                              )
                              :
                              script.script}
                          </Link>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            {script.hostname}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {script.findings[0] && script.findings[0].critical > 0 ? (
                        <Label color="error">
                          Critical:
                          {script.findings[0].critical}
                        </Label>
                      )
                        : '' }
                      {script.findings[0] && script.findings[0].high > 0 ? (
                        <Label
                          color="secondary"
                          sx={{
                            m: 1
                          }}
                        >
                          High:
                          {script.findings[0].high}
                        </Label>
                      )
                        : '' }
                      {script.findings[0] && script.findings[0].medium > 0 ? (
                        <Label
                          color="warning"
                          sx={{
                            m: 1
                          }}
                        >
                          Medium:
                          {script.findings[0].medium}
                        </Label>
                      )
                        : '' }
                      {script.findings[0] && script.findings[0].low > 0 ? (
                        <Label
                          color="primary"
                          sx={{
                            m: 1
                          }}
                        >
                          Low:
                          {script.findings[0].low}
                        </Label>
                      )
                        : '' }
                      {script.findings[0] && script.findings[0].info > 0 ? (
                        <Label
                          color="success"
                          sx={{
                            m: 1
                          }}
                        >
                          Info:
                          {script.findings[0].info}
                        </Label>
                      )
                        : '' }
                      {script.findings[0] && script.findings[0].info + script.findings[0].low + script.findings[0].medium + script.findings[0].high + script.findings[0].critical === 0 ? (
                        <b>
                          None
                        </b>
                      )
                        : ''}
                    </TableCell>
                    <TableCell>
                      {script.score > -1 && script.score !== null ? script.score : 'Not Analyzed'}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/dashboard/detail/script/${script.ID}`}
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={filteredScripts.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

ScriptListTable.propTypes = {
  scripts: PropTypes.array.isRequired
};

export default ScriptListTable;
