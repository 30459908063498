import { API } from 'aws-amplify';
import { useState, useEffect, useCallback } from 'react';
import { Box, Card, CardHeader, CardContent, CardActions, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import InformationCircleIcon from '../../../icons/InformationCircle';
import DuplicateIcon from '../../../icons/Duplicate';
import { OverviewAnalysisScript } from '../overview';

const copy = require('clipboard-copy');

function ShowClientScript(props) {
  const { instructions, header } = props;
  const message = (instructions === '' || instructions === undefined) ? 'Add this script to a page to start monitoring' : instructions;
  const title = (header === '' || header === undefined) ? 'Analysis Script' : header;
  const [clientData, setClientData] = useState([]);
  const isMountedRef = useIsMountedRef();

  const getClientScript = useCallback(async () => {
    try {
      const client = await API.get('surveyor-API', '/client');
      client.script = `<script>var srvyr_client_id = "${client.ID}"</script>
<script type="module" src="${process.env.REACT_APP_JS_MIN_URL}"></script>`;
      if (isMountedRef.current) {
        setClientData(client);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getClientScript();
  }, []);

  return (
    <Card>
      <CardHeader
        disableTypography
        subheader={(
          <Typography color="textPrimary" variant="h6">
            { message }
          </Typography>
        )}
        title={(
          <Box sx={{ alignItems: 'center', display: 'flex', pb: 2 }}>
            <InformationCircleIcon color="primary" />
            <Typography color="textPrimary" sx={{ pl: 1 }} variant="h6">
              { title }
            </Typography>
          </Box>
        )}
        sx={{ pb: 0 }}
      />
      <CardContent>
        <OverviewAnalysisScript script={clientData.script} />
      </CardContent>
      <CardActions sx={{ backgroundColor: 'background.default', p: 2 }}>
        <IconButton color="primary" size="small" onClick={() => { copy(clientData.script); }}>
          <DuplicateIcon />
          {' '}
          Copy to Clipboard
        </IconButton>
      </CardActions>
    </Card>
  );
}

ShowClientScript.propTypes = {
  instructions: PropTypes.string,
  header: PropTypes.string
};

export default ShowClientScript;
