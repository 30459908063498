import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { API } from 'aws-amplify';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import Label from '../../Label';
import Scrollbar from '../../Scrollbar';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const apiName = 'surveyor-API';

const applyPagination = (findings, page, limit) => findings
  .slice(page * limit, page * limit + limit);

function ScriptFindings(props) {
  const { user } = useAuth();
  const { scriptItems, ...other } = props;
  const [findings, setFindings] = useState([]);
  const isMountedRef = useIsMountedRef();
  const [p, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const path = (user.role === 'rdpt_admin' ? `/admin/findings/${scriptItems.ID}` : `/findings/${scriptItems.ID}`);
  const getFindings = useCallback(async () => {
    try {
      let response = await API.get(apiName, path);
      if (response === null) {
        response = [];
      } else {
        response.forEach((finding, index) => {
          if (finding.cve !== null && finding.cve !== 'n/a') {
            response[index].cve = finding.cve.split(',');
            response[index].hasCVEs = true;
          } else {
            response[index].cve = ['n/a'];
            response[index].hasCVEs = false;
          }
          if (finding.data !== null && finding.data !== 'n/a') {
            response[index].data = finding.data.split(',');
            response[index].hasFindings = true;
            console.log(response[index]);
          } else {
            response[index].data = ['n/a'];
            response[index].hasFindings = false;
            console.log(response[index]);
          }
        });
      }
      if (isMountedRef.current) {
        setFindings(response);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getFindings();
  }, [getFindings]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };
  const paginatedFindings = applyPagination(findings, p, limit);
  if (!findings) {
    return null;
  }
  return (
    <Card {...other}>
      <CardHeader title="Findings" />
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Severity
                </TableCell>
                <TableCell>
                  Summary
                </TableCell>
                <TableCell>
                  Recommendations
                </TableCell>
                <TableCell>
                  CVE(s)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedFindings.map((finding) => (
                <TableRow key={finding.ID}>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {finding.severity.toUpperCase() === 'CRITICAL' ? (
                        <Label color="error">
                          CRITICAL
                        </Label>
                      ) : finding.severity.toUpperCase() === 'HIGH' ? (
                        <Label color="secondary">
                          HIGH
                        </Label>
                      ) : finding.severity.toUpperCase() === 'MEDIUM' ? (
                        <Label color="warning">
                          MEDIUM
                        </Label>
                      ) : finding.severity.toUpperCase() === 'LOW' ? (
                        <Label color="primary">
                          LOW
                        </Label>
                      ) : finding.severity.toUpperCase() === 'INFO' ? (
                        <Label color="success">
                          INFORMATIONAL
                        </Label>
                      ) : '' }
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="left"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {finding.summary}
                      </Typography>
                      <Typography
                        align="left"
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {finding.hasFindings ? finding.data.map((d) => (
                          <div id={d}>
                            {d.search('http') === 0
                              ? (
                                <Link
                                  href={`${d}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {d}
                                </Link>
                              ) :
                              `${d}`}
                          </div>
                        )) :
                          'None'}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="left"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {finding.title}
                      </Typography>
                      <Typography
                        align="left"
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {finding.text}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography
                      align="left"
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {finding.hasCVEs ? finding.cve.map((c) => (
                        <div id={c}>
                          <Link
                            href={`https://nvd.nist.gov/vuln/detail/${c}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {c}
                          </Link>
                        </div>
                      )) :
                        'None'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={findings.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={p}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

ScriptFindings.propTypes = {
  scriptItems: PropTypes.array.isRequired
};

export default ScriptFindings;
