import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  // Checkbox,
  Divider,
  IconButton,
  // InputAdornment,
  // Link,
  // Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow,
  // Tabs,
  // TextField,
  Typography
} from '@mui/material';
import Label from '../../Label';
// import Label from '../../Label';
import ArrowRightIcon from '../../../icons/ArrowRight';
// import SearchIcon from '../../../icons/Search';
import Scrollbar from '../../Scrollbar';

const apiName = 'surveyor-API';
function ScanListTable(props) {
  const { scans, ...other } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [setSelectedTemplate] = useState([]);

  const handleRunScan = async (templateID) => {
    const scanPath = '/scan/run';
    const params = {
      body: {
        template_id: templateID
      }
    };
    const response = API.post(apiName, scanPath, params);
    console.log(response);
    if (response) {
      enqueueSnackbar('Scan Successful!', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        autoHideDuration: 5000,
        variant: 'success'
      });
      console.log(`Scan template ${templateID} has been run`);
      setSelectedTemplate([]);
    }
  };
  // console.log(scans);
  // console.log(scans[0].name);
  return (
    <Card {...other}>
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
        {/* {"ID": "ffb9beb7-a30a-4de1-995e-6626bbbb8926",
        "client_id": "925d8f9f-db20-11ea-8165-0279d7948544",
        "url_id": "1853d738-51a4-11ee-8572-063fbb657475",
        "host_id": "937a840d-5aff-11ed-84a2-0a22cf2ca804",
        "name": "vtm-idor-test-3",
        "created_at": "2023-09-15 21:55:25",
        "modified_at": "2023-09-15 21:59:49",
        "full_url": "https://vtm.rdpt.dev/taskManager/2/upload/",
        "latest_scan": [{"ID": "401fc4f0-804a-4c51-9014-bd85b85b57d4", "type": null, "status": "finished", "created_at": "2023-09-20 17:42:22", "modified_at": "2023-09-20 17:42:25", "client_id": "925d8f9f-db20-11ea-8165-0279d7948544", "host_id": "937a840d-5aff-11ed-84a2-0a22cf2ca804", "url_id": "1853d738-51a4-11ee-8572-063fbb657475", "template_id": "ffb9beb7-a30a-4de1-995e-6626bbbb8926", "results": {"template-id": "idor-vtm", "template-path": "/tmp/ffb9beb7-a30a-4de1-995e-6626bbbb8926.yaml", "info": {"name": "IDOR Vulnerability Template", "author": ["justin"], "tags": ["idor", "security", "bac", "bola"], "description": "This template attempts to find IDOR vulnerabilities.\n", "severity": "high"}, "matcher-name": "idor-on-profile-endpoint", "type": "http", "host": "https://vtm.rdpt.dev", "matched-at": "https://vtm.rdpt.dev/taskManager/profile/1", "extracted-results": ["8591"], */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  Severity
                </TableCell>
                <TableCell>
                  Scan Name
                </TableCell>
                <TableCell>
                  Host
                </TableCell>
                <TableCell>
                  URL
                </TableCell>
                {/* <TableCell align="center">
                  Created
                </TableCell> */}
                <TableCell align="center">
                  Last Scanned
                </TableCell>
                <TableCell align="center">
                  Status
                </TableCell>
                <TableCell align="right">
                  Run Scan
                </TableCell>
                <TableCell align="right">
                  Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scans.map((scan) => {
                // console.log(scan.ID);
                // console.log(scan.name);
                // console.log(scan.created_at);
                // console.log(scan.modified_at);
                console.log(scan);
                console.log(scan.latest_scan_results.length);
                // console.log(scan.latest_scan_results[0]['matcher-status']);
                // console.log(scan.latest_scan_results[0].severity);

                return (
                  <TableRow
                    hover
                    key={scan.ID}
                  >
                    <TableCell>
                      {scan.latest_scan_results.length > 0
                        ? (
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                            align="center"
                          >
                            {scan.latest_scan_results[0].severity === 'critical' ? (
                              <Label color="error">
                                CRITICAL
                              </Label>
                            ) : scan.latest_scan_results[0].severity === 'high' ? (
                              <Label color="secondary">
                                HIGH
                              </Label>
                            ) : scan.latest_scan_results[0].severity === 'medium' ? (
                              <Label color="warning">
                                MEDIUM
                              </Label>
                            ) : scan.latest_scan_results[0].severity === 'low' ? (
                              <Label color="primary">
                                LOW
                              </Label>
                            ) : scan.latest_scan_results[0].severity === 'info' ? (
                              <Label color="success">
                                INFORMATIONAL
                              </Label>
                            ) : '' }
                          </Typography>
                        )
                        : (
                          console.log(scans.length),
                          <Typography
                            color="textPrimary"
                            variant="subtitle1"
                            align="center"
                          >
                            -
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell width={200}>
                      <Typography
                        align="left"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {scan.name}
                      </Typography>
                    </TableCell>
                    <TableCell width={200}>
                      <Typography
                        align="left"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {scan.host}
                      </Typography>
                    </TableCell>
                    <TableCell width={200}>
                      <Typography
                        align="left"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {scan.url}
                      </Typography>
                    </TableCell>
                    {/* <TableCell width={100} align="right">
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="center"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {format(new Date(`${scan.created_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                        </Typography>
                        <Typography
                          align="center"
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {format(new Date(`${scan.created_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                        </Typography>
                      </Box>
                    </TableCell> */}
                    <TableCell width={100} align="right">
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="center"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {
                            scan.latest_scan && (
                              format(new Date(`${scan.latest_scan.modified_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()
                            )
                          }
                          {
                            !scan.latest_scan && (
                              <p>No Recent Scans</p>
                            )
                          }
                        </Typography>
                        <Typography
                          align="center"
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {
                            scan.latest_scan && (
                              format(new Date(`${scan.latest_scan.modified_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')
                            )
                          }
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ p: 1 }}>
                        {scan.latest_scan_results.length > 0
                          ? (
                            <Typography
                              align="right"
                              color="textPrimary"
                              variant="subtitle2"
                            >
                              {
                                scan.latest_scan_results[0]['matcher-status'] && (
                                  <Label color="error">Fail</Label>
                                )
                              }
                              {
                                !scan.latest_scan_results[0]['matcher-status'] && (
                                  <Label color="success">Pass</Label>
                                )
                              }
                            </Typography>
                          ) : (
                            <Typography
                              align="center"
                              variant="subtitle1"
                            >
                              -
                            </Typography>
                          )}
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Button onClick={() => handleRunScan(scan.ID)}>
                        Scan
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/dashboard/detail/scan/${scan.ID}`}
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
}

ScanListTable.propTypes = {
  scans: PropTypes.array.isRequired
};

export default ScanListTable;
