import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { Box, Divider, Drawer, Hidden, Link, Typography } from '@mui/material';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ExternalLink from '../../icons/ExternalLink';
import DocumentTextIcon from '../../icons/DocumentText';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import Collection from '../../icons/Collection';
import Bell from '../../icons/Bell';
import Exclamation from '../../icons/Exclamation';
import FolderOpen from '../../icons/FolderOpen';
// import Users from '../../icons/Users';

const sections = [
  {
    title: '',
    access: 2,
    items: [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />,
        access: 2
      },
      {
        title: 'Scripts',
        path: '/dashboard/detail/scripts',
        icon: <DocumentTextIcon fontSize="small" />,
        access: 2
      },
      {
        title: 'Pages',
        path: '/dashboard/detail/pages',
        icon: <ExternalLink fontSize="small" />,
        access: 2
      },
      {
        title: 'Hosts',
        path: '/dashboard/detail/hosts',
        icon: <Collection fontSize="small" />,
        access: 2
      },
      {
        title: 'Findings',
        path: '/dashboard/detail/findings',
        icon: <Exclamation fontSize="small" />,
        access: 2
      },
      {
        title: 'Notifications',
        path: '/dashboard/detail/notifications',
        icon: <Bell fontSize="small" />,
        access: 2
      },
      {
        title: 'Templates',
        path: '/dashboard/detail/scan',
        icon: <TrackChangesIcon fontSize="small" />,
        access: 2
      },
      {
        title: 'Documentation (Coming Soon)',
        path: '',
        icon: <FolderOpen fontSize="small" />,
        access: 2
      }
    ]

  /* }, // TODO: For future reference once we have implemented support channels
  {
    title: 'Support',
    items: [
      {
        title: 'Mail',
        path: '/dashboard/mail',
        icon: <MailIcon fontSize="small" />
      },
      {
        title: 'Chat',
        path: '/dashboard/chat',
        icon: <ChatAltIcon fontSize="small" />
      }
    ] */
  }/* , Moved to the AccountPopover component - but not sure about permissions there so I'm leaving it here until I get that figured out.
  {
    title: 'Management',
    access: 1,
    items: [
      {
        title: 'Users',
        path: '/dashboard/users',
        icon: <Users fontSize="small" />,
        access: 1
      },
      {
        title: 'Clients',
        path: '/dashboard/clients',
        icon: <Users fontSize="small" />,
        access: 0
      }
    ]
  } */
];

function DashboardSidebar(props) {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 1 }}>
          {sections.map((section) => (
            // NavSection contains the logic on when to display links based on current user's role
            <NavSection
              name={section.title}
              key={section.title}
              pathname={location.pathname}
              sx={{ '& + &': { mt: 3 } }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            Need Help?
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <Link color="primary" href="mailto:support@rdpt.io">
              support@rdpt.io
            </Link>
          </Typography>
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="right"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{ sx: { backgroundColor: 'background.paper', height: 'calc(100% - 64px) !important', top: '64px !Important', width: 180 } }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{ sx: { backgroundColor: 'background.paper', height: 'calc(100% - 64px) !important', top: '64px !Important', width: 180 } }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
