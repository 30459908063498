import PropTypes from 'prop-types';
import { useState } from 'react';
import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import Scrollbar from '../../Scrollbar';
import ArrowRightIcon from '../../../icons/ArrowRight';

const applyPagination = (scripts, page, limit) => scripts
  .slice(page * limit, page * limit + limit);

function PageScripts(props) {
  const { scripts, ...other } = props;
  const [p, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };
  const paginatedScripts = applyPagination(scripts, p, limit);
  if (!scripts) {
    return null;
  }
  return (
    <Card {...other}>
      <CardHeader title="Scripts" />
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Last Seen
                </TableCell>
                <TableCell>
                  First Seen
                </TableCell>
                <TableCell>
                  Script
                </TableCell>
                <TableCell>
                  Score
                </TableCell>
                <TableCell align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedScripts.map((script) => (
                <TableRow
                  hover
                  key={script.ID}
                >
                  <TableCell width={100}>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="center"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${script.last_seen.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                      </Typography>
                      <Typography
                        align="center"
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${script.last_seen.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell width={100}>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        align="center"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${script.created_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                      </Typography>
                      <Typography
                        align="center"
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {format(new Date(`${script.created_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box sx={{ ml: 1 }}>
                        <Link
                          color="inherit"
                          variant="subtitle2"
                        >
                          {script.type === 'inline'
                            ? (
                              <SyntaxHighlighter
                                {...props}
                                language="javascript"
                                style={vscDarkPlus}
                                wrapLongLines
                                showInlineLineNumbers
                              >
                                {script.first_line}
                              </SyntaxHighlighter>
                            )
                            :
                            script.script_url}
                        </Link>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {script.score > -1 && script.score !== null ? script.score : 'Not Analyzed'}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      component={RouterLink}
                      to={`/dashboard/detail/script/${script.ID}`}
                    >
                      <ArrowRightIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={scripts.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={p}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

PageScripts.propTypes = {
  scripts: PropTypes.array.isRequired
};

export default PageScripts;
