// import { useState } from 'react';
// import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import numeral from 'numeral';
// import { useSnackbar } from 'notistack';
import {
  Button,
  Box,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
// import useAuth from '../../../hooks/useAuth';
import InformationCircleIcon from '../../../icons/InformationCircle';
import Label from '../../Label';

// const apiName = 'surveyor-API';

function ScriptSummary(props) {
  const { script, ...other } = props;
  /*
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const handleReanalyze = async () => {
    const path = (user.role === 'rdpt_admin' ? `/admin/script/reanalyze/${script.ID}` : `/script/reanalyze/${script.ID}`);
    const response = await API.get(apiName, path);

    if (response) {
      enqueueSnackbar('Script being reanalyzed', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        autoHideDuration: 5000,
        variant: 'success'
      });
    }
  };
  console.log('here in scriptSummery');
  console.log(script);
  */
  return (
    <Card {...other}>
      <CardHeader
        disableTypography
        title={(
          <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="textPrimary" variant="h6">
              Script Info
            </Typography>
            <Tooltip
              placement="left-start"
              title={(
                <>
                  <Typography color="inherit">About Script Details</Typography>
                  <Table width={600}>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="h6"> Score </Typography>
                      </TableCell>
                      <TableCell> The score is based on its malicious rating determined by machine learning, does the script have known vulnerabilities and is the script served over TLS. The score is based out of 100.  </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label color="success"> Active </Label>
                      </TableCell>
                      <TableCell> The most recently analyzed version of this script </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label color="warning"> Inactive </Label>
                      </TableCell>
                      <TableCell> There is another version of the script more recently analyzed </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="h6"> Type </Typography>
                      </TableCell>
                      <TableCell>
                        <b>Inline</b>
                        - javascript that is contained within a page.
                        <Divider />
                        <b>Included</b>
                        - a javascript file that is included either from the host or externally
                      </TableCell>
                    </TableRow>
                  </Table>
                </>
              )}
            >
              <InformationCircleIcon fontSize="small" />
            </Tooltip>
          </Box>
        )}
      />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2"> Score </Typography>
            </TableCell>
            <TableCell>
              <Typography align="center" color="textPrimary" variant="subtitle2">
                {script.score}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2"> Type </Typography>
            </TableCell>
            <TableCell>
              <Typography align="center" color="textPrimary" variant="subtitle2">
                {script.type.toUpperCase()}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2"> Host </Typography>
            </TableCell>
            <TableCell>
              <Typography align="center" color="textPrimary" variant="subtitle2">
                {script.hostname}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2"> Last Seen Date </Typography>
            </TableCell>
            <TableCell>
              <Box sx={{ p: 1 }}>
                <Typography align="center" color="textPrimary" variant="subtitle2">
                  {format(new Date(`${script.last_seen.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                </Typography>
                <Typography align="center" color="textSecondary" variant="subtitle2">
                  {format(new Date(`${script.last_seen.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2"> Line Count </Typography>
            </TableCell>
            <TableCell>
              <Typography align="center" color="textPrimary" variant="subtitle2">
                {numeral(script.line_count)
                  .format(`${script.line_count}0,0`)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2"> Number of times this script was seen </Typography>
            </TableCell>
            <TableCell>
              <Typography align="center" color="textPrimary" variant="subtitle2">
                {numeral(script.events)
                  .format(`${script.events}0,0`)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Number of pages with this script
              </Typography>
            </TableCell>
            <TableCell>
              <Typography align="center" color="textPrimary" variant="subtitle2">
                {numeral(script.location_count)
                  .format(`${script.location_count}0,0`)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2"> Notications </Typography>
            </TableCell>
            <TableCell>
              <Typography align="center" color="textPrimary" variant="subtitle2">
                {numeral(script.notifications)
                  .format(`${script.notifications}0,0`)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2"> Total Findings </Typography>
            </TableCell>
            <TableCell>
              <Typography align="center" color="textPrimary" variant="subtitle2">
                {numeral(script.totalFindings)
                  .format(`${script.totalFindings}0,0`)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2"> Hash </Typography>
            </TableCell>
            <TableCell>
              <Typography align="center" color="textPrimary" variant="subtitle2">
                {script.hash_code}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardActions>
        <Button color="primary" startIcon={<BuildIcon fontSize="small" />} variant="text"/* onClick={handleReanalyze} */>
          Reanalyze Script
        </Button>
      </CardActions>
    </Card>
  );
}

ScriptSummary.propTypes = {
  // @ts-ignore
  script: PropTypes.object.isRequired
};

export default ScriptSummary;
