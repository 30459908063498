import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import Label from '../../Label';

const apiName = 'surveyor-API';

// If latest scan value is null, display button to run a scan
function ScanLatest(props) {
  const { scan, ...other } = props;
  const { scanId } = useParams();
  const [latest, setLatest] = useState(null);
  const path = `/scan/template/${scanId}/results`;
  useEffect(async () => {
    try {
      const response = await API.get(apiName, path);
      await setLatest(response);
      console.log(response);
    } catch (err) {
      console.error(err);
    }
  }, []);
  console.log(path);
  console.log(scanId);
  console.log(scan);
  console.log(latest);

  console.log(scan['matcher-status']);
  console.log(scan[scan.length - 1]['matcher-status']);

  if (!scan) {
    return (
      <Typography
        align="center"
        color="textSecondary"
        variant="body1"
      >
        No scan found
      </Typography>
    );
  }

  return (
    <Card {...other}>
      <CardHeader
        disableTypography
        title={(
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Scan Result Details
            </Typography>
          </Box>
        )}
      />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Scan ID
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {scan[(scan.length - 1)].ID}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Client ID
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {scan[(scan.length - 1)].client_id}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Status
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                {scan[(scan.length - 1)].status.charAt(0).toUpperCase() + scan[(scan.length - 1)].status.slice(1)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Result
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle1"
                align="center"
              >
                {scan[scan.length - 1]['matcher-status'] === true ? (
                  <Label color="error" align="center">
                    FAIL
                  </Label>
                ) : scan[scan.length - 1]['matcher-status'] === false ? (

                  <Label color="secondary" align="center">
                    PASS
                  </Label>
                ) : '' }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Scan Finished At
              </Typography>
            </TableCell>
            <TableCell>
              <Box sx={{ p: 1 }}>
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {format(new Date(`${scan[(scan.length - 1)].created_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                </Typography>
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {format(new Date(`${scan[(scan.length - 1)].created_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
}

ScanLatest.propTypes = {
  // @ts-ignore
  scan: PropTypes.object.isRequired
};

export default ScanLatest;
